import createReducerFactory from "helpers/createReducerFactory";
import createReducerState from "helpers/createReducerState";
import defaultState from "constants/defaultState";

import { loadTopVideos, loadTopUsers, loadVideoInfoByMediaId } from "./routines";

const initialState = {
  topVideos: defaultState,
  topUsers: defaultState,
  videoInfoByMediaId: defaultState
};

const factories = {
  ...createReducerState(loadTopVideos, "topVideos"),
  ...createReducerState(loadTopUsers, "topUsers"),
  ...createReducerState(loadVideoInfoByMediaId, "videoInfoByMediaId")
};

export default createReducerFactory(initialState, factories);
