import { takeLatest, all, call, put, select } from "redux-saga/effects";

import createRequest from "helpers/createRequest";

import * as routines from "./routines";

function* LoadAllWatcher({ payload = {} }) {
  try {
    yield put(routines.loadAll.request());

    const response = yield call(createRequest.get, "/teachers", {
      params: {
        per_page: payload.per_page?payload.per_page:20,
        is_verified: payload.is_verified,
        is_active: payload.is_active        
      }
    });
    const { data } = response.data.teachers;

    yield put(routines.loadAll.success({ data }));
  } catch (error) {
    yield put(routines.loadAll.failure());
  } finally {
    yield put(routines.loadAll.fulfill());
  }
}
function* LoadStudentsAllWatcher({ payload = {} }) {
  try {
    yield put(routines.loadStudentsAll.request());
    const { teacher_id }=payload
    const response = yield call(createRequest.get, `/teachers/${teacher_id}/students`);
    const { data } = response.data.students;

    yield put(routines.loadStudentsAll.success({ data }));
  } catch (error) {
    yield put(routines.loadStudentsAll.failure());
  } finally {
    yield put(routines.loadStudentsAll.fulfill());
  }
}

function* LoadOneWatcher({ payload }) {
  const { teacher_id } = payload;

  try {
    yield put(routines.loadOne.request());

    const response = yield call(createRequest.get, `/teachers/${teacher_id}`);
    const students_response = yield call(createRequest.get, `/teachers/${teacher_id}/students`);

    const data = response.data.teacher;
    const students_data=response.data.students
    yield put(routines.loadStudentsAll.success({ students_data }));
    yield put(routines.loadOne.success({ data }));

  } catch (error) {
    yield put(routines.loadOne.failure());
    yield put(routines.loadStudentsAll.failure());

  } finally {
    yield put(routines.loadOne.fulfill());
    yield put(routines.loadStudentsAll.fulfill());
  }
}

function* CreateWatcher({ payload }) {
  const { values, onSuccess = () => {}, onError = () => {} } = payload;

  try {
    yield put(routines.create.request());

    const response = yield call(createRequest.post, "/teachers", { role_id: 3, ...values });

    yield put(routines.create.success(response.data.teacher));
    yield call(onSuccess);
  } catch (error) {
    yield put(routines.create.failure());
    yield call(onError, error);
  } finally {
    yield put(routines.create.fulfill());
  }
}

function* UploadImageWatcher({ payload }) {
  const { file, onSuccess = () => {}, onError = () => {} } = payload;

  try {
    yield put(routines.uploadImage.request());

    const formData = new FormData();

    formData.append("image", file);
    formData.append("_method", "PUT");

    const { data } = yield call(createRequest.post, "/upload/user-image", formData);

    yield put(routines.uploadImage.success(data));
    yield call(onSuccess, data);
  } catch (error) {
    yield put(routines.uploadImage.failure(error));
    yield call(onError, error);
  } finally {
    yield put(routines.uploadImage.fulfill());
  }
}

function* UpdateWatcher({ payload }) {
  const { teacher_id, values, onSuccess = () => {}, onError = () => {} } = payload;

  try {
    yield put(routines.update.request());

    const response = yield call(createRequest.patch, `/teachers/${teacher_id}`, { ...values });

    yield put(routines.update.success(response.data.teacher));
    yield call(onSuccess);
  } catch (error) {
    yield put(routines.update.failure());
    yield call(onError, error);
  } finally {
    yield put(routines.update.fulfill());
  }
}

function* DeleteOneWatcher({ payload }) {
  const teachers = yield select(state => state.Teacher.all);

  const { user_id, onSuccess = () => {}, onError = () => {} } = payload;

  try {
    yield call(createRequest.delete, `/teachers/${user_id}`);

    const newTeachers = (teachers?.data || []).filter(teacher => teacher.id != user_id);
    yield put(routines.loadAll.success({ data: newTeachers, meta: teachers?.meta }));

    yield call(onSuccess);
  } catch (error) {
    yield put(routines.deleteOne.failure(error));
    yield call(onError, error?.response);
  } finally {
    yield put(routines.deleteOne.fulfill());
  }
}

export default function*() {
  yield all([
    takeLatest(routines.loadAll.TRIGGER, LoadAllWatcher),
    takeLatest(routines.loadStudentsAll.TRIGGER, LoadStudentsAllWatcher),
    takeLatest(routines.loadOne.TRIGGER, LoadOneWatcher),
    takeLatest(routines.create.TRIGGER, CreateWatcher),
    takeLatest(routines.uploadImage.TRIGGER, UploadImageWatcher),
    takeLatest(routines.update.TRIGGER, UpdateWatcher),
    takeLatest(routines.deleteOne.TRIGGER, DeleteOneWatcher)
  ]);
}
