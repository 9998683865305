// import cookies from "services/cookies";

// import { login, logout } from "./routines";

// const { access_token, user, user_permissions, session } = cookies.getAll();

// const initialState = {
//   isAuthenticated: !!access_token,
//   access_token: access_token || null,
//   user: user || {},
//   user_permissions: user_permissions || [],
//   session: session || {}
// };

// export default (state = initialState, action) => {
//   switch (action.type) {
//     case login.SUCCESS: {
//       const { access_token, user, user_permissions, session } = action.payload;

//       return {
//         ...state,
//         isAuthenticated: true,
//         access_token,
//         user,
//         user_permissions,
//         session
//       };
//     }
//     default:
//       return state;
//   }
// };

import createReducerFactory from "helpers/createReducerFactory";
import createReducerState from "helpers/createReducerState";
import createFormReducer from "helpers/createFormReducer";
import defaultState from "constants/defaultState";

import { register,confirm, login, resend, courses, selectcourse, buycourse, loadOrganizations, listOfCourses } from "./routines";

const initialState = {
  register: defaultState,
  confirm: defaultState,
  login: defaultState,
  resend: defaultState,
  courses: defaultState,
  selectcourse: defaultState,
  buycourse: defaultState,
  loadOrganizations: defaultState,
  listOfCourses: defaultState
};

const factories = {
  ...createReducerState(register, "register"),
  ...createReducerState(confirm, "confirm"),
  ...createFormReducer(login, "login"),
  ...createFormReducer(resend, "resend"),
  ...createReducerState(courses, "courses"),
  ...createFormReducer(selectcourse, "selectcourse"),
  ...createFormReducer(buycourse, "buycourse"),
  ...createReducerState(loadOrganizations, "loadOrganizations"),
  ...createReducerState(listOfCourses, "listOfCourses")
};

export default createReducerFactory(initialState, factories);
