import { createRoutine } from "redux-saga-routines";

export const login = createRoutine("auth/login");
export const logout = createRoutine("AUTH/LOGOUT");
export const register = createRoutine("AUTH/REGISTER");
export const confirmPhone = createRoutine("AUTH/CONFIRM_PHONE");
export const validatePhone = createRoutine("AUTH/VALIDATE_PHONE");
export const sendCode = createRoutine("AUTH/SEND_CODE");
export const verifyCode = createRoutine("AUTH/VERIFY_CODE");
export const resetPassword = createRoutine("AUTH/RESET_PASSWORD");
