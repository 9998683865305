import { takeLatest, all, call, put, select } from "redux-saga/effects";

import axios from "axios";
import get from "lodash/get";
import findIndex from "lodash/findIndex";
import _merge from "lodash/merge";

import createRequest from "helpers/createRequest";
import * as courseRoutines from "modules/Course/routines";

import createRequestWithoutLang from "helpers/createRequestWithoutLang";


import * as routines from "./routines";

function* LoadOneWatcher({ payload }) {
  try {
    yield put(routines.loadOne.request());
    
    const { data } = yield call(createRequest.get, `/modules/${payload.unit_id}`);
    // const response = yield call(createRequest.get, `${video_id_cost}`);
    // const { video, video_link } = response.data;
    // yield put(routines.loadOne.success({ data: { ...video, video_link } }));
    yield put(routines.loadOne.success({ data: data.module }));
  } catch (error) {
    yield put(routines.loadOne.failure());
  } finally {
    yield put(routines.loadOne.fulfill());
  }
}
// function* LoadOneAttachment({payload}) {
//   try {
//     yield put(routines.loadOneAttachment.request());

//     const { attachment } = yield call(createRequest.get, `/units/${payload.unit_id}/materials`);
//     console.log(attachment);
//     yield put(routines.loadOneAttachment.success({ attachment: attachment.unit_materials }));
//   } catch(error) {
//     yield put(routines.loadOneAttachment.failure());
//   } finally {
//     yield put(routines.loadOneAttachment.fulfill());
//   }
// }
function* LoadCommentsWatcher({ payload }) {
  try {
    yield put(routines.loadComments.request());

    const unit_files = yield call(createRequest.get, `/modules/${payload.video_id}/units?_lang=ru`);

    yield put(routines.loadComments.success( { data: unit_files.data.units }));
  } catch (error) {
    yield put(routines.loadComments.failure());
  } finally {
    yield put(routines.loadComments.fulfill());
  }
}

function* ReplyCommentWatcher({ payload: { values, onSuccess = () => {}, onError = () => {} } }) {
  const oldVideoComments = yield select(state => state.Video.comments);

  const oldVideoCommentId = findIndex(oldVideoComments.data, comment => comment.id == values.comment_id);
  let oldVideoComment = oldVideoComments.data[oldVideoCommentId];

  try {
    yield put(routines.replyComment.request());

    const response = yield call(createRequest.post, "/comment-replies", { ...values });

    const { comment_reply } = response.data;

    const newVideoComment = { ...oldVideoComment, replies: [...oldVideoComment.replies, comment_reply] };

    yield put(routines.replyComment.success(newVideoComment));
    yield call(onSuccess);
  } catch (error) {
    yield put(routines.replyComment.failure());
    onError(error);
  } finally {
    yield put(routines.replyComment.fulfill());
  }
}

function* UploadImageWatcher({ payload }) {
  const { file, onSuccess = () => {}, onError = () => {} } = payload;

  try {
    yield put(routines.uploadImage.request());

    const formData = new FormData();

    formData.append("image", file);
    formData.append("_method", "PUT");

    const { data } = yield call(createRequest.post, "/upload/video-image", formData);

    yield put(routines.uploadImage.success(data));
    yield call(onSuccess, data);
  } catch (error) {
    yield put(routines.uploadImage.failure(error));
    yield call(onError, error);
  } finally {
    yield put(routines.uploadImage.fulfill());
  }
}

function* UploadAttachmentWatcher({ payload }) {
  const { file, onSuccess = () => {}, onError = () => {} } = payload;
  try {
    yield put(routines.uploadAttachment.request());
    
    const formData = new FormData();
    const get_video_id = window.location.pathname.substring(10);
    formData.append("file", file);
    formData.append("_method", "PUT");
    //here must to uncomment
    const { data } = yield call(createRequest.post, `${get_video_id}/materials/files`, formData);
    yield put(routines.uploadAttachment.success(data));

    yield call(onSuccess, data);
  } catch (error) {
    yield put(routines.uploadAttachment.failure(error));
    yield call(onError, error);
  } finally {
    yield put(routines.uploadAttachment.fulfill());
  }
}

function* LoadUploadLinkWatcher({ payload }) {

  try {
    yield put(routines.loadUploadLink.request());

    const response = "";//yield call(createRequest.get, `units/${payload.video_id}/materials`);

    const { link, media } = response.data;

    yield put(routines.loadUploadLink.success({ data: { link, media } }));
  } catch (error) {
    yield put(routines.loadUploadLink.failure());
  } finally {
    yield put(routines.loadUploadLink.fulfill());
  }
}

function* UploadFileWatcher({ payload }) {

  const { file, onSuccess = () => {}, onError = () => {}, onUploadProgress = () => {} } = payload;

  try {
    yield put(routines.uploadFile.request());

    const formData = new FormData();
    formData.append("file", file);

    const { data } = yield call(axios.post, `units/${payload.video_id}/materials/files`, formData, { onUploadProgress });

    yield put(routines.uploadFile.success({ data }));
    yield call(onSuccess, data.media);
  } catch (error) {
    yield put(routines.uploadFile.failure());
    yield call(onError, error);
  } finally {
    yield put(routines.uploadFile.fulfill());
  }
}

function* DeleteUnitWatcher({ payload }) {
  const { video_id, onSuccess = () => {}, onError = () => {} } = payload;

  try {
    yield put(routines.deleteUnit.request());

    yield call(createRequest.delete, `/units/${payload.unit}`);

    yield put(routines.deleteUnit.success({}));
    yield call(onSuccess);
  } catch (error) {
    yield put(routines.deleteUnit.failure());
    yield call(onError, error);
  } finally {
    yield put(routines.deleteUnit.fulfill());
  }
}

function* CreateWatcher({ payload }) {
  const {
    values: { mediaId, ...values },
    onSuccess = () => {},
    onError = () => {}
  } = payload;
  try {
    yield put(routines.create.request());
    const { data } = yield call(createRequestWithoutLang.post, `/modules?_lang=${values.lang.name}`, {
      course_id: values.course_id,
      description: values.description,
      name: values.name
    });
    yield put(routines.create.success({ data: data.video }));
    yield put(courseRoutines.addVideo.success(data.video));

    yield call(onSuccess, data.video);
  } catch (error) {
    yield put(routines.create.failure());
    yield call(onError, error);
  } finally {
    yield put(routines.create.fulfill());
  }
}

function* AddAttachmentWatcher({ payload }) {
  const { values, onSuccess = () => {}, onError = () => {} } = payload;
  try {
    yield put(routines.addAttachment.request());
    //function which save uploaded file
    const { data } = yield call(createRequestWithoutLang.post, `/units?_lang=${values.lang.name}`, {
      description: values.description,
      module_id: values.module_id,
      name: values.name,
      teacher_id: values.teacher_id
    });

    const video = yield select(state => state.Video.one);
    console.log(video);
    yield put(
      routines.loadOne.success({
        data: {
          ...video.data,
          references: [...get(video, ["data", "references"], []), data.video_reference]
        }
      })
    );

    yield call(onSuccess, data.video_reference);
  } catch (error) {
    yield put(routines.addAttachment.failure());
    yield call(onError, error);
  } finally {
    yield put(routines.addAttachment.fulfill());
  }
}

function* DeleteOneWatcher({ payload }) {
  const { video_id, onSuccess = () => {}, onError = () => {} } = payload;

  try {
    yield put(routines.deleteOne.request());

    yield call(createRequest.delete, `/modules/${payload.unit_id}`);

    yield put(routines.deleteOne.success({}));
    yield call(onSuccess);
  } catch (error) {
    yield put(routines.deleteOne.failure());
    yield call(onError, error);
  } finally {
    yield put(routines.deleteOne.fulfill());
  }
}

function* ArchiveWatcher({ payload }) {
  const { video_id, is_archived } = payload;

  try {
    yield put(routines.loadOne.request());

    const { data } = yield call(createRequest.patch, `/videos/${video_id}/archive`, {
      is_archived
    });

    yield put(routines.loadOne.success({ data: data.video }));
  } catch (error) {
    yield put(routines.loadOne.failure());
  } finally {
    yield put(routines.loadOne.fulfill());
  }
}

function* SetTrialWatcher({ payload }) {
  const { video_id, is_trial } = payload;

  try {
    yield put(routines.loadOne.request());

    const { data } = yield call(createRequest.patch, `/videos/${video_id}/trial`, {
      is_trial
    });

    yield put(routines.loadOne.success({ data: data.video }));
  } catch (error) {
    yield put(routines.loadOne.failure());
  } finally {
    yield put(routines.loadOne.fulfill());
  }
}

function* UpdateOneWatcher({ payload }) {
  const { video_id, values, onSuccess = () => {}, onError = () => {} } = payload;
  try {
    yield put(routines.updateOne.request());

    const { data } = yield call(createRequestWithoutLang.patch, `/modules/${video_id}?_lang=${values.lang.name}`, { 
      name: values.name,
      description: values.description
     });

    yield put(routines.updateOne.success({ data: data.video }));
    yield put(routines.loadOne.success({ data: data.video }));

    yield call(onSuccess, data.video);
  } catch (error) {
    yield put(routines.updateOne.failure());
    yield call(onError, error);
  } finally {
    yield put(routines.updateOne.fulfill());
  }
}

function* UpdateCommentReplyWatcher({ payload }) {
  const { parent_id, comment_id, values, onSuccess = () => {}, onError = () => {} } = payload;

  try {
    yield put(routines.updateCommentReply.request());

    const response = yield call(createRequest.patch, `/comment-replies/${comment_id}`, { ...values });

    const { comment_reply } = response.data;

    let oldVideoComments = yield select(state => state.Video.comments);

    const parentIndex = findIndex(oldVideoComments.data, i => i.id == parent_id);
    const index = findIndex(oldVideoComments.data[parentIndex].replies, i => i.id == comment_id);

    oldVideoComments.data[parentIndex].replies[index] = comment_reply;

    yield put(routines.updateCommentReply.success());
    yield put(routines.loadComments.success(oldVideoComments));
    yield call(onSuccess);
  } catch (error) {
    yield put(routines.updateCommentReply.failure());
    onError(error);
  } finally {
    yield put(routines.updateCommentReply.fulfill());
  }
}

function* DeleteCommentReplyWatcher({ payload }) {
  const { parent_id, comment_id, onSuccess = () => {}, onError = () => {} } = payload;

  try {
    yield put(routines.deleteCommentReply.request());

    yield call(createRequest.delete, `/comment-replies/${comment_id}`);

    let oldVideoComments = yield select(state => state.Video.comments);

    const parentIndex = findIndex(oldVideoComments.data, i => i.id == parent_id);
    const index = findIndex(oldVideoComments.data[parentIndex].replies, i => i.id == comment_id);

    delete oldVideoComments.data[parentIndex].replies[index];

    yield put(routines.deleteCommentReply.success());
    yield put(routines.loadComments.success(oldVideoComments));
    yield call(onSuccess);
  } catch (error) {
    yield put(routines.deleteCommentReply.failure());
    onError(error);
  } finally {
    yield put(routines.deleteCommentReply.fulfill());
  }
}

export default function*() {
  yield all([
    takeLatest(routines.loadOne.TRIGGER, LoadOneWatcher),
    // takeLatest(routines.loadOneAttachment.TRIGGER, LoadOneAttachment),
    takeLatest(routines.loadComments.TRIGGER, LoadCommentsWatcher),
    takeLatest(routines.replyComment.TRIGGER, ReplyCommentWatcher),
    takeLatest(routines.uploadImage.TRIGGER, UploadImageWatcher),
    takeLatest(routines.uploadAttachment.TRIGGER, UploadAttachmentWatcher),
    takeLatest(routines.loadUploadLink.TRIGGER, LoadUploadLinkWatcher),
    takeLatest(routines.uploadFile.TRIGGER, UploadFileWatcher),
    takeLatest(routines.create.TRIGGER, CreateWatcher),
    takeLatest(routines.addAttachment.TRIGGER, AddAttachmentWatcher),
    takeLatest(routines.deleteOne.TRIGGER, DeleteOneWatcher),
    takeLatest(routines.deleteUnit.TRIGGER, DeleteUnitWatcher),
    takeLatest(routines.archive.TRIGGER, ArchiveWatcher),
    takeLatest(routines.setTrial.TRIGGER, SetTrialWatcher),
    takeLatest(routines.updateOne.TRIGGER, UpdateOneWatcher),
    takeLatest(routines.updateCommentReply.TRIGGER, UpdateCommentReplyWatcher),
    takeLatest(routines.deleteCommentReply.TRIGGER, DeleteCommentReplyWatcher)
  ]);
}
