import createReducerFactory from "helpers/createReducerFactory";
import createReducerState from "helpers/createReducerState";
import defaultState from "constants/defaultState";

import { profile, uploadDocs, orders, update , updateInfo, uploadImage, documents } from "./routines";

const initialState = {
  profile: defaultState,
  uploadDocs: defaultState,
  orders: defaultState,
  update: defaultState,
  updateInfo: defaultState,
  uploadImage: defaultState,
  documents: defaultState
};

const factories = {
  ...createReducerState(profile, "profile"),
  ...createReducerState(uploadDocs, "uploadDocs"),
  ...createReducerFactory(orders, "orders"),
  ...createReducerState(update, "update"),
  ...createReducerState(updateInfo, "updateInfo"),
  ...createReducerState(uploadImage, "uploadImage"),
  ...createReducerState(documents, "documents")

};

export default createReducerFactory(initialState, factories);
