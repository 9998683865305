import { takeLatest, all, call, put } from "redux-saga/effects";
import cookies from "services/cookies";
import { authRequest } from "helpers/createRequest";
import * as routines from "./routines";
import { take } from "lodash";
import { Cookies } from "react-cookie";

function* LoginWatcher({ payload }) {
  const { values, onSuccess = () => {}, onError = () => {} } = payload;

  try {
    const { email, password, session_id, user_agent, device_type, browser, browser_version } = values;

    yield put(routines.login.request());

    const response = yield call(authRequest.post, "/auth/login", {
      email,
      password
    });

    const { user_role, access_token, user, user_permissions } = response.data;

    cookies.set("access_token", access_token, { path: "/" });
    
    yield put(
      routines.login.success({
        access_token
      })
    );
    yield call(onSuccess);
  } catch (error) {
    yield put(routines.login.failure());
    if (error?.response) {
      yield call(onError, error.response);
    }
  } finally {
    yield put(routines.login.fulfill());
  }
}

function* RegisterWatcher({ payload }) {
  const { values, onSuccess = () => {}, onError = () => {} } = payload;

  try {
    const { organization_id, name, phone_number, email, password, password_confirmation, passport_no, passport_issuer, address_living, address_register, address_working } = values;

    yield put(routines.register.request());

    const response = yield call(authRequest.post, "/auth/register", {
      organization_id,
      name,
      phone_number,
      email,
      password,
      password_confirmation,
      passport_no,
      passport_issuer,
      address_living,
      address_register,
      address_working
    });

    const {} = response.data;

    yield put(routines.register.success({ data: {} }));
    yield call(onSuccess);
  } catch (error) {
    yield put(routines.register.failure());
    if (error.response) {
      yield call(onError, error.response);
    }
  } finally {
    yield put(routines.register.fulfill());
  }
}

function* ConfirmPhoneWatcher({ payload }) {
  const { values, onSuccess = () => {}, onError = () => {} } = payload;

  try {
    const { email, token } = values;

    yield put(routines.confirm.request());

    const response = yield call(authRequest.post, "/auth/register/confirm", {
      email,
      token
    });

    const {} = response.data;

    yield put(routines.confirm.success({ data: {} }));
    yield call(onSuccess);
  } catch (error) {
    yield put(routines.confirm.failure());
    yield call(onError, error);
  } finally {
    yield put(routines.confirm.fulfill());
  }
}

function* ResendConfirm({ payload }) {
  const { values, onSuccess = () => {}, onError = () => {} } = payload;

  try {
    const { email } = values;

    yield put(routines.confirm.request());

    const response = yield call(authRequest.post, "/auth/register/resend", {
      email
    });

    const {} = response.data;

    yield put(routines.confirm.success({ data: {} }));
    yield call(onSuccess);
  } catch (error) {
    yield put(routines.confirm.failure());
    yield call(onError, error);
  } finally {
    yield put(routines.confirm.fulfill());
  }
}

function* LoadOrganizations() {
  try {
    yield put(routines.loadOrganizations.request());

    const response = yield call(authRequest.get, "auth/register/organizations");
    const data = response.data.organizations;
    yield put(routines.loadOrganizations.success({ data }));
  } catch (error) {
    yield put(routines.loadOrganizations.failure());
  } finally {
    yield put(routines.loadOrganizations.fulfill());
  }
}

function* LoadPaymentCourse() {
  try {
    yield put(routines.courses.request());
    const access_token = cookies.get("access_token");
    const response = yield call(authRequest.get, "payment/categories?flatten=1&_lang=ru&access_token=" + access_token);

    const data = response.data.categories;
    yield put(routines.courses.success({ data }));
  } catch (error) {
    yield put(routines.courses.failure());
  } finally {
    yield put(routines.courses.fulfill());
  }
}

function* SelectCourse({ payload }) {
  const { values, onSuccess = () => {}, onError = () => {} } = payload;
  const course_id = payload.course_id;
  try {

    yield put(routines.selectcourse.request());
    const access_token = cookies.get("access_token");

    const response = yield call(authRequest.post, "payment/order/create?_lang=ru&access_token=" + access_token, {
      course_id
    });
    const data = response.data.order;
    if(!cookies.get("order_id")) {
      cookies.set("order_id", response.data.order.id);
    }
    else {
      cookies.remove("order_id");
      cookies.set("order_id", response.data.order.id);
    }
    yield put(routines.selectcourse.success({ data }));
    yield call(onSuccess);
  } catch (error) {
    yield put(routines.selectcourse.failure());
    yield call(onError, error);
  } finally {
    yield put(routines.selectcourse.fulfill());
  }
}

function* listOfCourses({ payload }) {

  try {
    yield put(routines.listOfCourses.request());
    const access_token = cookies.get("access_token");

    const response = yield call(authRequest.get, `payment/categories/${payload}/courses?_lang=ru&access_token=${access_token}`);

    const data = response.data.courses;
    yield put(routines.listOfCourses.success({ data }));
  } catch (error) {
    yield put(routines.listOfCourses.failure());
  } finally {
    yield put(routines.listOfCourses.fulfill());
  }
}

function* BuyCourse({ payload }) {
  const { values, onSuccess = () => {}, onError = () => {} } = payload;

  try {
    const { order_id, payment_method } = values;

    yield put(routines.buycourse.request());
    const access_token = cookies.get("access_token");

    const response = yield call(authRequest.post, "payment/order/checkout?access_token="+ access_token, {
      order_id, payment_method
    });
    const data = response.data;
    yield put(routines.buycourse.success({ data }));
    window.open(data.checkout_link);
    yield call(onSuccess);
  } catch (error) {
    yield put(routines.buycourse.failure());
    yield call(onError, error);
  } finally {
    yield put(routines.buycourse.fulfill());
  }
}

export default function*() {
  yield all([
    takeLatest(routines.login.TRIGGER, LoginWatcher),
    takeLatest(routines.register.TRIGGER, RegisterWatcher),
    takeLatest(routines.confirm.TRIGGER, ConfirmPhoneWatcher),
    takeLatest(routines.resend.TRIGGER, ResendConfirm),
    takeLatest(routines.courses.TRIGGER, LoadPaymentCourse),
    takeLatest(routines.loadOrganizations.TRIGGER, LoadOrganizations),
    takeLatest(routines.selectcourse.TRIGGER, SelectCourse),
    takeLatest(routines.buycourse.TRIGGER, BuyCourse),
    takeLatest(routines.listOfCourses.TRIGGER, listOfCourses),
  ]);
}
