import { takeLatest, all, call, put } from "redux-saga/effects";
import _get from "lodash/get";

import createRequest, { authRequest } from "helpers/createRequest";

import * as routines from "./routines";
import cookies from "services/cookies";

function* LoadAllWatcher({ payload }) {
  const token = cookies.get("access_token");
  try {
    yield put(routines.info.request());

    const response = yield call(authRequest.get, `/teacher/tests?access_token=${token}&_lang=ru`, {
      params: {
        page: payload.page,
        per_page: payload.per_page,
        category_id: payload.category_id,
        organization_id: payload.organization_id,
        type: payload.type,
        ...(payload.filter ? { [payload.filter]: 1 } : {}),
      }
    });
    const { data, ...meta } = response.data.tests;

    yield put(routines.info.success({ data, meta }));
  } catch (error) {
    yield put(routines.info.failure());
  } finally {
    yield put(routines.info.fulfill());
  }
}


function* LoadAllStudents({ payload }) {
  const token = cookies.get("access_token");
  try {
    yield put(routines.students.request());

    const response = yield call(authRequest.get, `/teacher/users?access_token=${token}&_lang=ru`, {
      params: {
        page: payload.page,
        per_page: payload.per_page,
        category_id: payload.category_id,
        organization_id: payload.organization_id,
        type: payload.type,
        ...(payload.filter ? { [payload.filter]: 1 } : {}),
      }
    });
    const { data, ...meta } = response.data.students;

    yield put(routines.students.success({ data, meta }));
  } catch (error) {
    yield put(routines.students.failure());
  } finally {
    yield put(routines.students.fulfill());
  }
}

function* LoadOneWatcher({ payload }) {
  const token = cookies.get("access_token");

  try {
    yield put(routines.loadOne.request());

    const response = yield call(authRequest.get, `/teacher/users/${payload.user_id}/courses?access_token=${token}`);

    const data = response.data.user_courses;

    yield put(routines.loadOne.success({ data }));
  } catch (error) {
    yield put(routines.loadOne.failure());
  } finally {
    yield put(routines.loadOne.fulfill());
  }
}


function* LoadFinalTest({ payload }) {
  const token = cookies.get("access_token");

  try {
    yield put(routines.allowTest.request());
    console.log(payload);
    const response = yield call(authRequest.patch, `/teacher/tests/final?access_token=${token}`, {      
      user_id: payload.user_id,
      is_allowed: payload.is_allowed
    });

    const data = response.data.status;

    yield put(routines.allowTest.success({ data }));
  } catch (error) {
    yield put(routines.allowTest.failure());
  } finally {
    yield put(routines.allowTest.fulfill());
  }
}

  
export default function*() {
  yield all([
    takeLatest(routines.info.TRIGGER, LoadAllWatcher),
    takeLatest(routines.students.TRIGGER, LoadAllStudents),
    takeLatest(routines.loadOne.TRIGGER, LoadOneWatcher),
    takeLatest(routines.allowTest.TRIGGER, LoadFinalTest)
  ]);
}
