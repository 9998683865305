import { takeLatest, all, call, put, select } from "redux-saga/effects";
import get from "lodash/get";
import findIndex from "lodash/findIndex";

import createRequest from "helpers/createRequest";
import createRequestWithoutLang from "helpers/createRequestWithoutLang";

import * as routines from "./routines";

function* LoadAllWatcher() {
  try {
    yield put(routines.loadAll.request());

    const response = yield call(createRequest.get, "/categories");

    const data = response.data.categories;

    yield put(routines.loadAll.success({ data }));
  } catch (error) {
    yield put(routines.loadAll.failure());
  } finally {
    yield put(routines.loadAll.fulfill());
  }
}

function* LoadAllWatcherFlatten() {
  try {
    yield put(routines.loadAllFlatten.request());

    const response = yield call(createRequest.get, "/categories?flatten=1");

    const data = response.data.categories;

    yield put(routines.loadAllFlatten.success({ data }));
  } catch (error) {
    yield put(routines.loadAllFlatten.failure());
  } finally {
    yield put(routines.loadAllFlatten.fulfill());
  }
}
function* LoadOneWatcher() {
  yield put(routines.loadOne.request({}));
}

function* CreateWatcher({ payload }) {
  const categories = yield select(state => state.Category.all);

  const { values, onSuccess = () => {}, onError = () => {} } = payload;
  try {
    yield put(routines.create.request());

    const response = yield call(createRequestWithoutLang.post, `/categories?_lang=${values.lang.name}`, { 
      description: values.description,
      discount: values.discount,
      image: values.image,
      name: values.name,
      parent_id: values.parent_id,
      price: values.price,
      thumbnail: values.thumbnail
     });

    let data = response.data.categories;

    if (payload.values.parent_id) {
      let parentCategory = categories.data.find(parent => parent.id == payload.values.parent_id);
      parentCategory.subcategories = [...get(parentCategory, "subcategories", []), data];
      data = parentCategory;
      yield put(routines.update.success(data));

    } else {
      yield put(routines.create.success(data));

    }

    yield call(onSuccess);
  } catch (error) {
    yield put(routines.create.failure());
    yield call(onError, error);
  } finally {
    yield put(routines.create.fulfill());
  }
}
function* UpdateWatcher({ payload }) {
  const categories = yield select(state => state.Category.all);

  const { category_id, values, onSuccess = () => {}, onError = () => {} } = payload;
  try {
    yield put(routines.update.request());

    const response = yield call(createRequestWithoutLang.patch, `/categories/${category_id}?_lang=${values.lang.name}`, {
      description: values.description,
      discount: values.discount,
      image: values.image,
      name: values.name,
      parent_id: values.parent_id,
      price: values.price,
      thumbnail: values.thumbnail
    });

    let data = response.data.category;

    if (payload.values.parent_id) {
      let parentCategory = categories.data.find(parent => parent.id == payload.values.parent_id);
      const newSubcategoryId = findIndex(parentCategory.subcategories, i => i.id === category_id);
      parentCategory.subcategories[newSubcategoryId] = data;

      yield put(routines.update.success(parentCategory));
    } else {
      yield put(routines.update.success(data));
    }

    yield call(onSuccess);
  } catch (error) {
    yield put(routines.update.failure());
    yield call(onError, error);
  } finally {
    yield put(routines.update.fulfill());
  }
}

function* DeleteOneWatcher({ payload }) {
  const categories = yield select(state => state.Category.all);

  const { category_id, parent_id, onSuccess = () => {}, onError = () => {} } = payload;

  try {
    yield put(routines.deleteOne.request());

    yield call(createRequest.delete, `/categories/${category_id}`);

    if (parent_id) {
      let parentCategory = categories.data.find(parent => parent.id == parent_id);
      let newSubcategories = (parentCategory.subcategories || []).filter(item => item.id != category_id);
      parentCategory.subcategories = newSubcategories;

      yield put(routines.update.success(parentCategory));
    } else {
      let newCategories = categories.data.filter(item => item.id != category_id);

      yield put(routines.loadAll.success({ data: newCategories }));
    }

    yield put(routines.deleteOne.success({}));
    yield call(onSuccess);
  } catch (error) {
    yield put(routines.deleteOne.failure());
    yield call(onError, error);
  } finally {
    yield put(routines.deleteOne.fulfill());
  }
}

function* UploadImageWatcher({ payload }) {
  const { file, onSuccess = () => {}, onError = () => {} } = payload;

  try {
    yield put(routines.uploadImage.request());

    const formData = new FormData();

    formData.append("image", file);
    formData.append("_method", "PUT");

    const { data } = yield call(createRequest.post, "/categories/image", formData);

    yield put(routines.uploadImage.success(data));
    yield call(onSuccess, data);
  } catch (error) {
    yield put(routines.uploadImage.failure(error));
    yield call(onError, error);
  } finally {
    yield put(routines.uploadImage.fulfill());
  }
}

export default function*() {
  yield all([
    takeLatest(routines.loadAll.TRIGGER, LoadAllWatcher),
    takeLatest(routines.loadAllFlatten.TRIGGER, LoadAllWatcherFlatten),
    takeLatest(routines.loadOne.TRIGGER, LoadOneWatcher),
    takeLatest(routines.create.TRIGGER, CreateWatcher),
    takeLatest(routines.update.TRIGGER, UpdateWatcher),
    takeLatest(routines.deleteOne.TRIGGER, DeleteOneWatcher),
    takeLatest(routines.uploadImage.TRIGGER, UploadImageWatcher)
  ]);
}
