// Themes
import "assets/scss/DefaultTheme.scss";

import "react-toastify/dist/ReactToastify.css";
import "react-quill/dist/quill.snow.css";
import "flatpickr/dist/themes/light.css";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter as Router } from "react-router-dom";
import { CookiesProvider } from "react-cookie";

import { subscribe } from "./helpers/createRequest";
import { subscrib } from "./helpers/createRequestWithoutLang";
import { configureStore } from "./redux/store";
import i18n from "./i18n";
import App from "./App";

const store = configureStore();

subscribe(store);
subscrib(store);

ReactDOM.render(
  <CookiesProvider>
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <Router>
          <App />
        </Router>
      </I18nextProvider>
    </Provider>
  </CookiesProvider>,
  document.getElementById("root")
);
