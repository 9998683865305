import React, { Suspense, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";

import NodeWaves from "node-waves";

import routes from "routes";

export default () => {
  const currentLanguage = useSelector(state => state.System.language);
  const { i18n } = useTranslation();
  const date = new Date();

  useEffect(() => {
    NodeWaves.init();
  }, []);

  useEffect(() => {
    i18n.changeLanguage(currentLanguage);
    if (!localStorage.getItem("language")) {
      localStorage.setItem("language", currentLanguage);
    }
  }, [currentLanguage]);
  return (
    <Fragment>
      <ToastContainer />

      <Suspense fallback="Loading...">
        {routes.map((route, key) => (
          <Route key={key} {...route} />
        ))}
      </Suspense>
      {/* <Redirect from="/" to="/dashboard" /> */}
    </Fragment>
  );
};
