import { createRoutine } from "redux-saga-routines";

export const loadOne = createRoutine("UNITS/LOAD_ONE");
export const loadAllFiles = createRoutine("UNITS/LOAD_FILES");
export const loadComments = createRoutine("UNITS/LOAD_COMMENTS");
export const replyComment = createRoutine("UNITS/REPLY_COMMENT");
export const uploadImage = createRoutine("UNITS/UPLOAD_IMAGE");
export const uploadAttachment = createRoutine("UNITS/UPLOAD_ATTACHMENT");
export const loadUploadLink = createRoutine("UNITS/LOAD_UPLOAD_LINK");
export const uploadFile = createRoutine("UNITS/UPLOAD_FILE");
export const create = createRoutine("UNITS/CREATE");
export const addAttachment = createRoutine("UNITS/ADD_ATTACHMENT");
export const deleteOne = createRoutine("UNITS/DELETE_ONE");
export const archive = createRoutine("UNITS/ARCHIVE");
export const setTrial = createRoutine("UNITS/SET_TRIAL");
export const updateOne = createRoutine("UNITS_UPDATE_ONE");
export const updateCommentReply = createRoutine("UNITS/UPDATE_COMMENT-REPLY");
export const deleteCommentReply = createRoutine("UNITS/DELETE_COMMENT-REPLY");
