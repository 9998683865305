import { loadAll, update, uploadFile } from "./routines";

const initialState = {
  loading: false,
  data: {
    ids: [],
    entities: {}
  },
  upload: {
    loading: false,
    data: null,
    error: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case uploadFile.REQUEST: {
      return { ...state, upload: { ...state.upload, loading: true } };
    }
    case uploadFile.SUCCESS: {
      return { ...state, upload: { loading: false, data: action.payload.data } };
    }
    case uploadFile.FAILURE: {
      return { ...state, upload: { loading: false, error: action.payload.error } };
    }

    case loadAll.REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case loadAll.SUCCESS: {
      const {
        data: { ids, entities },
        meta
      } = action.payload;

      return {
        ...state,
        loading: false,
        data: { ids, entities },
        meta
      };
    }

    case loadAll.FAILURE: {
      return {
        ...state,
        loading: false
      };
    }

    case update.SUCCESS: {
      const { id, entity } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          entities: {
            ...state.data.entities,
            [id]: entity
          }
        }
      };
    }

    default:
      return state;
  }
};
