import { takeLatest, all, call, put } from "redux-saga/effects";

import createRequest from "helpers/createRequest";

import * as routines from "./routines";

function* LoadAllWatcher({ payload }) {
  try {
    yield put(routines.loadAll.request());

    const response = yield call(createRequest.get, "/transactions", {
      params: {
        page: payload.page,
        method: payload.method
      }
    });
    const { data, ...meta } = response.data.transactions;

    yield put(routines.loadAll.success({ data, meta }));
  } catch (error) {
    yield put(routines.loadAll.failure());
  } finally {
    yield put(routines.loadAll.fulfill());
  }
}
function* LoadOneWatcher() {
  yield put(routines.loadOne.request({}));
}

export default function*() {
  yield all([
    takeLatest(routines.loadAll.TRIGGER, LoadAllWatcher),
    takeLatest(routines.loadOne.TRIGGER, LoadOneWatcher)
  ]);
}
