import createReducerFactory from "helpers/createReducerFactory";
import createReducerState from "helpers/createReducerState";
import createFormReducer from "helpers/createFormReducer";
import defaultState from "constants/defaultState";

import { loadAll, loadOne, loadModule, loadUnit, loadUnitMaterials, loadUnitTest, loadUnitTestOption, moduleTest, moduleTestOne, moduleTestSave, moduleTestFinish, courseTest, courseTestOne, courseTestSave, courseTestFinish } from "./routines";

const initialState = {
  all: defaultState,
  one: defaultState,
  module: defaultState,
  unit: defaultState,
  unitmaterial: defaultState,
  loadUnitTest: defaultState,
  loadUnitTestOption: defaultState,
  moduleTest: defaultState,
  moduleTestOne: defaultState,
  moduleTestSave: defaultState,
  moduleTestFinish: defaultState,
  courseTest: defaultState,
  courseTestOne: defaultState,
  courseTestSave: defaultState,
  courseTestFinish: defaultState
};

const factories = {
  ...createReducerState(loadAll, "all"),
  ...createReducerState(loadOne, "one"),
  ...createReducerState(loadModule, "module"),
  ...createReducerState(loadUnit, "unit"),
  ...createReducerState(loadUnitMaterials, "unitmaterial"),
  ...createReducerState(loadUnitTest, "loadUnitTest"),
  ...createReducerState(loadUnitTestOption, "loadUnitTestOption"),
  ...createReducerState(moduleTest, "moduleTest"),
  ...createReducerState(moduleTestOne, "moduleTestOne"),
  ...createReducerState(moduleTestSave, "moduleTestSave"),
  ...createReducerState(moduleTestFinish, "moduleTestFinish"),
  ...createReducerState(courseTest, "courseTest"),
  ...createReducerState(courseTestOne, "courseTestOne"),
  ...createReducerState(courseTestSave, "courseTestSave"),
  ...createReducerState(courseTestFinish, "courseTestFinish")
};

export default createReducerFactory(initialState, factories);
