import { createRoutine } from "redux-saga-routines";

export const loadOne = createRoutine("VIDEO/LOAD_ONE");
export const loadOneAttachment = createRoutine("VIDEO/LOAD_ATTACHMENT");
export const loadComments = createRoutine("VIDEO/LOAD_COMMENTS");
export const replyComment = createRoutine("VIDEO/REPLY_COMMENT");
export const uploadImage = createRoutine("VIDEO/UPLOAD_IMAGE");
export const uploadAttachment = createRoutine("VIDEO/UPLOAD_ATTACHMENT");
export const loadUploadLink = createRoutine("VIDEO/LOAD_UPLOAD_LINK");
export const uploadFile = createRoutine("VIDEO/UPLOAD_FILE");
export const create = createRoutine("VIDEO/CREATE");
export const addAttachment = createRoutine("VIDEO/ADD_ATTACHMENT");
export const deleteOne = createRoutine("VIDEO/DELETE_ONE");
export const deleteUnit = createRoutine("UNIT/DELETE");
export const archive = createRoutine("VIDEO/ARCHIVE");
export const setTrial = createRoutine("VIDEO/SET_TRIAL");
export const updateOne = createRoutine("VIDEO_UPDATE_ONE");
export const updateCommentReply = createRoutine("VIDEO/UPDATE_COMMENT-REPLY");
export const deleteCommentReply = createRoutine("VIDEO/DELETE_COMMENT-REPLY");
