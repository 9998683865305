import { createRoutine } from "redux-saga-routines";

export const register = createRoutine("AUTH/REGISTER");
export const confirm = createRoutine("AUTH/CONFIRM");
export const login = createRoutine("AUTH/LOGIN");
export const resend = createRoutine("AUTH/RESEND");
export const courses = createRoutine("AUTH/COURSES");
export const selectcourse = createRoutine("AUTH/SELECTCOURSE");
export const buycourse = createRoutine("AUTH/BUY");
export const loadOrganizations = createRoutine("AUTH/ORGANIZATION");
export const listOfCourses = createRoutine("AUTH/LISTCOURSES");