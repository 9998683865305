import { loadAll, change, loadGroups } from "./routines";

const initialState = {
  loading: false,
  groups: {
    loading: false,
    data: []
  },
  data: {
    ids: [],
    entities: {}
  },
  meta: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case loadGroups.REQUEST: {
      return {
        ...state,
        groups: {
          ...state.groups,
          loading: true
        }
      };
    }

    case loadGroups.FAILURE: {
      return {
        ...state,
        groups: {
          ...state.groups,
          loading: false
        }
      };
    }

    case loadGroups.SUCCESS: {
      return {
        ...state,
        groups: {
          ...state.groups,
          loading: false,
          data: action.payload
        }
      };
    }

    case loadAll.REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case loadAll.SUCCESS: {
      const {
        data: { ids, entities },
        meta
      } = action.payload;

      return {
        ...state,
        loading: false,
        data: { ids, entities },
        meta
      };
    }

    case loadAll.FAILURE: {
      return {
        ...state,
        loading: false
      };
    }

    case change.SUCCESS: {
      const { id, entity } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          entities: {
            ...state.data.entities,
            [id]: entity
          }
        }
      };
    }

    default:
      return state;
  }
};
