import axios from "axios";

import { BASE_URL, AUTH_BASE_URL } from "constants/variables";
import cookies from "services/cookies";

import * as authRoutines from "modules/Auth/routines";

const request = axios.create({
  baseURL: BASE_URL
});

export const authRequest = axios.create({
  baseURL: AUTH_BASE_URL
});

export const subscribe = store => {
  request.interceptors.request.use(config => {
    const state = store.getState();
    const { access_token } = state.Auth;
    const _lang = state.System.language;

    if (access_token) {
      config.headers.Authorization = ["Bearer", access_token].join(" ");
    }

    config.params = { ...config.params, _lang };
    config.params = { ...config.params };

    return config;
  });

  request.interceptors.response.use(
    response => response,
    error => {
      if (error?.response?.status == "401") {
        store.dispatch(authRoutines.logout.success());
        cookies.remove("token", { path: "/" });
      }
      throw error;
    }
  );
};

export default request;
