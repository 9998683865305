import { takeLatest, all, call, put } from "redux-saga/effects";
import { normalize } from "normalizr";

import createRequest from "helpers/createRequest";

import * as routines from "./routines";
import { translation as translationSchema } from "./schemas";

function* LoadAllWatcher({ payload }) {
  const { group_id } = payload;

  try {
    yield put(routines.loadAll.request());

    const { data } = yield call(createRequest.get, "/translations", {
      params: {
        group_id
      }
    });

    const { result, entities } = normalize(data?.translations, [translationSchema]);

    yield put(
      routines.loadAll.success({
        data: {
          ids: result,
          entities: entities.translation
        },
        meta: {}
      })
    );
  } catch (error) {
    yield put(routines.loadAll.failure());
  } finally {
    yield put(routines.loadAll.fulfill());
  }
}

function* ChangeWatcher({ payload }) {
  const {
    values: { is_visible, key, value },
    onSuccess = () => {},
    onError = () => {}
  } = payload;

  try {
    yield put(routines.change.request());

    const { data } = yield call(createRequest.patch, "/translations", {
      is_visible,
      key,
      value
    });

    yield put(
      routines.change.success({
        id: data?.translation?.key,
        entity: data?.translation
      })
    );

    yield call(onSuccess);
  } catch (error) {
    yield put(routines.change.failure());
    yield call(onError, error?.response);
  } finally {
    yield put(routines.change.fulfill());
  }
}

function* LoadGroupsWatcher() {
  try {
    yield put(routines.loadGroups.request());

    const { data } = yield call(createRequest.get, "/translation-groups");

    yield put(routines.loadGroups.success(data.groups));
  } catch (error) {
    yield put(routines.loadGroups.failure());
  } finally {
    yield put(routines.loadGroups.fulfill());
  }
}

export default function*() {
  yield all([
    takeLatest(routines.loadAll.TRIGGER, LoadAllWatcher),
    takeLatest(routines.change.TRIGGER, ChangeWatcher),
    takeLatest(routines.loadGroups.TRIGGER, LoadGroupsWatcher)
  ]);
}
