import dayjs from "dayjs";

// export const API_URL = "sudex.napaautomative.com";
export const API_URL = "api.mtsudex.uz";
export const BASE_URL = `http://${API_URL}/admin/`;
export const AUTH_BASE_URL = `http://${API_URL}/`;
export const FILE_URL = `http://${API_URL}/storage`;
export const CURRENCY = "UZS";
export const SERVER_DATE_FORMAT = "YYYY-MM-DD hh:mm:ss";
export const DATE_FORMAT = "DD.MM.YYYY";
export const DATE_FORMAT_FULL = "DD.MM.YYYY hh:mm:ss";
export const DASHBOARD_FILTER_DATE_FORMAT = "YYYY-MM-DD";
export const DEFAULT_START_DATE = dayjs(new Date()).startOf("M").format(DASHBOARD_FILTER_DATE_FORMAT);
export const DEFAULT_END_DATE = dayjs(new Date()).endOf("M").format(DASHBOARD_FILTER_DATE_FORMAT);
