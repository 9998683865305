import { takeLatest, all, call, put } from "redux-saga/effects";
import { normalize } from "normalizr";

import createRequest from "helpers/createRequest";

import * as routines from "./routines";
import { asset as AssetSchema } from "./schemas";

function* LoadAllWatcher({ payload }) {
  try {
    yield put(routines.loadAll.request());

    const { data } = yield call(createRequest.get, "/assets");

    const { result, entities } = normalize(data?.assets, [AssetSchema]);

    yield put(
      routines.loadAll.success({
        data: {
          ids: result,
          entities: entities.asset
        }
      })
    );
  } catch (error) {
    yield put(routines.loadAll.failure());
  } finally {
    yield put(routines.loadAll.fulfill());
  }
}

function* UpdateWatcher({ payload }) {
  const {
    values: { is_visible, type, key, label, url },
    onSuccess = () => {},
    onError = () => {}
  } = payload;

  try {
    yield put(routines.update.request());

    const { data } = yield call(createRequest.patch, "/assets", {
      is_visible,
      type,
      key,
      label,
      url
    });

    yield put(
      routines.update.success({
        id: data?.asset?.key,
        entity: data?.asset
      })
    );

    yield call(onSuccess);
  } catch (error) {
    yield put(routines.update.failure());
    yield call(onError, error?.response);
  } finally {
    yield put(routines.update.fulfill());
  }
}

function* UploadFileWatcher({ payload }) {
  const { file, onSuccess = () => {}, onError = () => {}, onUploadProgress = () => {} } = payload;

  try {
    yield put(routines.uploadFile.request());

    const formData = new FormData();

    formData.append("file", file);
    formData.append("_method", "PUT");

    const { data } = yield call(createRequest.post, "/upload/assets", formData, { onUploadProgress });

    yield put(routines.uploadFile.success({ data }));
    yield call(onSuccess, data);
  } catch (error) {
    yield put(routines.uploadFile.failure({ error }));
    yield call(onError, error);
  } finally {
    yield put(routines.uploadFile.fulfill());
  }
}

export default function*() {
  yield all([
    takeLatest(routines.loadAll.TRIGGER, LoadAllWatcher),
    takeLatest(routines.update.TRIGGER, UpdateWatcher),
    takeLatest(routines.uploadFile.TRIGGER, UploadFileWatcher)
  ]);
}
