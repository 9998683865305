import createReducerFactory from "helpers/createReducerFactory";
import createReducerState from "helpers/createReducerState";
import createFormReducer from "helpers/createFormReducer";
import defaultState from "constants/defaultState";

import { loadAll, loadOne, loadVideos, create, uploadImage, addVideo, deleteOne, compulsory } from "./routines";

const initialState = {
  all: defaultState,
  one: defaultState,
  videos: defaultState,
  create: defaultState,
  image: defaultState,
  deleteOne: defaultState,
  compulsory: defaultState
};

const factories = {
  ...createReducerState(loadAll, "all"),
  ...createReducerState(loadOne, "one"),
  ...createReducerState(loadVideos, "videos"),
  ...createFormReducer(create, "create"),
  ...createReducerState(uploadImage, "image"),
  ...createFormReducer(addVideo, "create", "videos"),
  ...createReducerState(deleteOne, "deleteOne"),
  ...createReducerState(compulsory, "compulsory")
};

export default createReducerFactory(initialState, factories);
