import { takeLatest, all, call, put } from "redux-saga/effects";
import _get from "lodash/get";

import createRequest, { authRequest } from "helpers/createRequest";

import * as routines from "./routines";
import cookies from "services/cookies";

function* LoadUserProfile() {

  try {
    yield put(routines.profile.request());
    const token = cookies.get('access_token');
    const { data } = yield call(authRequest.get, `/student/profile?access_token=${token}`);
    yield put(routines.profile.success({ data: data.user }));
  } catch (error) {
    yield put(routines.profile.failure());
  } finally {
    yield put(routines.profile.fulfill());
  }
}

function* SetActivity() {

  try {
    yield put(routines.activity.request());
    const token = cookies.get('access_token');
    const { data } = yield call(authRequest.post, `/student/activity?access_token=${token}`);
    yield put(routines.activity.success({ data: data.status }));
  } catch (error) {
    yield put(routines.activity.failure());
  } finally {
    yield put(routines.activity.fulfill());
  }
}

function* LoadUserDocuments() {

  try {
    yield put(routines.documents.request());
    const token = cookies.get('access_token');
    const { data } = yield call(authRequest.get, `/student/profile/docs?access_token=${token}`);
    yield put(routines.documents.success({ data: data.user_docs }));
  } catch (error) {
    yield put(routines.documents.failure());
  } finally {
    yield put(routines.documents.fulfill());
  }
}


function* UploadDocsWatcher({ payload }) {

  const { file, onSuccess = () => {}, onError = () => {} } = payload;

  try {
    yield put(routines.uploadDocs.request());

    const formData = new FormData();

    formData.append("file", file);
    formData.append("type", payload.object)
    formData.append("_method", "PUT");
    const token = cookies.get('access_token');
    const { data } = yield call(authRequest.post, `/student/profile/docs?access_token=${token}`, formData);

    yield put(routines.uploadDocs.success(data));
    yield call(onSuccess, data);
  } catch (error) {
    yield put(routines.uploadDocs.failure(error));
    yield call(onError, error);
  } finally {
    yield put(routines.uploadDocs.fulfill());
  }
}


function* LoadUserOrders() {

  try {
    yield put(routines.orders.request());
    const token = cookies.get('access_token');
    const { data } = yield call(authRequest.get, `/student/profile/orders?access_token=${token}`);
    yield put(routines.orders.success({ data: data.orders }));
  } catch (error) {
    yield put(routines.orders.failure());
  } finally {
    yield put(routines.orders.fulfill());
  }
}

function* UpdateWatcher({ payload }) {
  const {  val, onSuccess = () => {}, onError = () => {} } = payload;

  try {
    const token = cookies.get('access_token');


    yield put(routines.update.request());
    const response = yield call(authRequest.patch, `/student/profile/password?access_token=${token}`, { ...val });

    yield put(routines.update.success(response.data.profile));
    yield call(onSuccess, val);
  } catch (error) {
    yield put(routines.update.failure());
    yield call(onError, error);
  } finally {
    yield put(routines.update.fulfill());
  }
}

function* UpdateInfoWatcher({ payload }) {

  const {  values, onSuccess = () => {}, onError = () => {} } = payload;

  try {
    const token = cookies.get('access_token');


    yield put(routines.updateInfo.request());
    const response = yield call(authRequest.patch, `/student/profile?access_token=${token}`, { ...values });

    yield put(routines.updateInfo.success(response.data.profile));
    yield call(onSuccess);
  } catch (error) {
    yield put(routines.updateInfo.failure());
    yield call(onError, error);
  } finally {
    yield put(routines.updateInfo.fulfill());
  }
}


function* UploadImageWatcher({ payload }) {
  const { file, onSuccess = () => {}, onError = () => {} } = payload;

  try {
    yield put(routines.uploadImage.request());

    const formData = new FormData();

    formData.append("image", file);
    formData.append("_method", "PUT");
    const token = cookies.get('access_token');

    const { data } = yield call(authRequest.post, `/student/profile/image?access_token=${token}`, formData);

    yield put(routines.uploadImage.success(data));
    yield call(onSuccess, data);
  } catch (error) {
    yield put(routines.uploadImage.failure(error));
    yield call(onError, error);
  } finally {
    yield put(routines.uploadImage.fulfill());
  }
}

  
export default function*() {
  yield all([
    takeLatest(routines.profile.TRIGGER, LoadUserProfile),
    takeLatest(routines.uploadDocs.TRIGGER, UploadDocsWatcher),
    takeLatest(routines.orders.TRIGGER, LoadUserOrders),
    takeLatest(routines.update.TRIGGER, UpdateWatcher),
    takeLatest(routines.updateInfo.TRIGGER, UpdateInfoWatcher),
    takeLatest(routines.uploadImage.TRIGGER, UploadImageWatcher),
    takeLatest(routines.documents.TRIGGER, LoadUserDocuments),
    takeLatest(routines.activity.TRIGGER, SetActivity)
  ]);
}
