import createSagaMiddleware from "redux-saga";

export const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

if (process.env.NODE_ENV === "development") {
  const { createLogger } = require("redux-logger");
  const loggerMiddleware = createLogger();

  middlewares.push(loggerMiddleware);
}

export default middlewares;
