const list = {
  ru: { title: "Русский" },
  uz: { title: "Ўзбек" }
};

var get_lang = localStorage.getItem("language");

if(get_lang == "null") {
  localStorage.setItem("language", "ru");
}
else if(get_lang == null) {
  localStorage.setItem("language", "ru");
}

const defaultValue = localStorage.getItem("language");

export default {
  list,
  defaultValue
};
