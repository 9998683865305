import { takeLatest, all, call, put, select } from "redux-saga/effects";

import createRequest from "helpers/createRequest";

import * as routines from "./routines";

function* LoadAllWatcher({ payload = {} }) {
  try {
    yield put(routines.loadAll.request());

    const response = yield call(createRequest.get, "/admins", {
      params: {
        per_page: payload.per_page?payload.per_page:15,
        is_verified: payload.is_verified,
        is_active: payload.is_active
      }
    });

    const { data } = response.data.admins;

    yield put(routines.loadAll.success({ data }));
  } catch (error) {
    yield put(routines.loadAll.failure());
  } finally {
    yield put(routines.loadAll.fulfill());
  }
}
function* LoadOneWatcher( payload ) {
  const { admin_id } = payload

  try {
    yield put(routines.loadOne.request());

    const response = yield call(createRequest.get, `/admins/${admin_id}` );

    const data = response.data.admin;

    yield put(routines.loadOne.success({ data }));
  } catch (error) {
    yield put(routines.loadOne.failure());
  } finally {
    yield put(routines.loadOne.fulfill());
  }
}

function* CreateWatcher({ payload }) {
  const { values, onSuccess = () => {}, onError = () => {} } = payload;

  try {
    yield put(routines.create.request());

    const response = yield call(createRequest.post, "/admins", {role_id: 3, ...values});

    yield put(routines.create.success(response.data.admin));
    yield call(onSuccess);
  } catch (error) {
    yield put(routines.create.failure());
    yield call(onError, error);
  } finally {
    yield put(routines.create.fulfill());
  }
}

function* DeleteOneWatcher({ payload }) {
  const admins = yield select(state => state.Admin.all);

  const { user_id, onSuccess = () => {}, onError = () => {} } = payload;

  try {
    yield call(createRequest.delete, `/admins/${user_id}`);

    const newAdmins = (admins?.data || []).filter(admin => admin.id != user_id);
    yield put(routines.loadAll.success({ data: newAdmins, meta: admins?.meta }));

    yield call(onSuccess);
  } catch (error) {
    yield put(routines.deleteOne.failure(error));
    yield call(onError, error?.response);
  } finally {
    yield put(routines.deleteOne.fulfill());
  }
}

export default function*() {
  yield all([
    takeLatest(routines.loadAll.TRIGGER, LoadAllWatcher),
    takeLatest(routines.loadOne.TRIGGER, LoadOneWatcher),
    takeLatest(routines.create.TRIGGER, CreateWatcher),
    takeLatest(routines.deleteOne.TRIGGER, DeleteOneWatcher)
  ]);
}
