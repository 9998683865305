import createReducerFactory from "helpers/createReducerFactory";
import createReducerState from "helpers/createReducerState";
import createFormReducer from "helpers/createFormReducer";
import defaultState from "constants/defaultState";

import {
  loadOne,
  loadComments,
  replyComment,
  uploadImage,
  uploadAttachment,
  loadUploadLink,
  deleteUnit,
  uploadFile,
  addAttachment,
  deleteOne
} from "./routines";

const initialState = {
  one: defaultState,
  comments: defaultState,
  image: defaultState,
  attachment: defaultState,
  uploadLink: defaultState,
  file: defaultState,
  deleteUnit: defaultState,
  deleteOne: defaultState
};

const factories = {
  ...createReducerState(loadOne, "one"),
  ...createReducerState(loadComments, "comments"),
  ...createFormReducer(replyComment, "update", "comments"),
  ...createReducerState(uploadImage, "image"),
  ...createReducerState(deleteUnit, "deleteUnit"),
  ...createReducerState(uploadAttachment, "attachment"),
  ...createReducerState(loadUploadLink, "uploadLink"),
  ...createReducerState(uploadFile, "file"),
  ...createFormReducer(addAttachment, "update", "one"),
  ...createReducerState(deleteOne, "deleteOne")
};

export default createReducerFactory(initialState, factories);
