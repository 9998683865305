import { takeLatest, all, call, put, select } from "redux-saga/effects";

import createRequest from "helpers/createRequest";

import * as routines from "./routines";

function* LoadAllWatcher({ payload }) {
  try {
    yield put(routines.loadAll.request());

    const response = yield call(createRequest.get, "/subscriptions", {
      params: {}
    });

    const data = response.data.subscriptions;

    yield put(routines.loadAll.success({ data }));
  } catch (error) {
    yield put(routines.loadAll.failure());
  } finally {
    yield put(routines.loadAll.fulfill());
  }
}
function* LoadOneWatcher() {
  yield put(routines.loadOne.request({}));
}

function* CreateWatcher({ payload }) {
  const { values, onSuccess = () => {}, onError = () => {} } = payload;

  try {
    yield put(routines.create.request());

    const response = yield call(createRequest.post, "/subscriptions", { ...values });

    yield put(routines.create.success(response.data.subscription));
    yield call(onSuccess);
  } catch (error) {
    yield put(routines.create.failure());
    yield call(onError, error);
  } finally {
    yield put(routines.create.fulfill());
  }
}

function* UpdateWatcher({ payload }) {
  const { subscription_id, values, onSuccess = () => {}, onError = () => {} } = payload;

  try {
    yield put(routines.update.request());

    const response = yield call(createRequest.patch, `/subscriptions/${subscription_id}`, { ...values });

    yield put(routines.update.success(response.data.subscription));
    yield call(onSuccess);
  } catch (error) {
    yield put(routines.update.failure());
    yield call(onError, error);
  } finally {
    yield put(routines.update.fulfill());
  }
}

function* DeleteOneWatcher({ payload }) {
  const { subscription_id, onSuccess = () => {}, onError = () => {} } = payload;

  try {
    yield put(routines.deleteOne.request());

    yield call(createRequest.delete, `/subscriptions/${subscription_id}`);

    const subscription = yield select(state => state.Subscription.all.data);
    const newSubscription = subscription.filter(i => i.id != subscription_id);

    yield put(routines.loadAll.success({ data: newSubscription }));
    yield put(routines.deleteOne.success({}));
    yield call(onSuccess);
  } catch (error) {
    yield put(routines.deleteOne.failure());
    yield call(onError, error);
  } finally {
    yield put(routines.deleteOne.fulfill());
  }
}

export default function*() {
  yield all([
    takeLatest(routines.loadAll.TRIGGER, LoadAllWatcher),
    takeLatest(routines.loadOne.TRIGGER, LoadOneWatcher),
    takeLatest(routines.create.TRIGGER, CreateWatcher),
    takeLatest(routines.update.TRIGGER, UpdateWatcher),
    takeLatest(routines.deleteOne.TRIGGER, DeleteOneWatcher)
  ]);
}
