import { createRoutine } from "redux-saga-routines";


export const loadAll = createRoutine("COURSE/LOAD_ALL");
export const loadOne = createRoutine("COURSE/LOAD_ONE");
export const loadVideos = createRoutine("COURSE/LOAD_VIDEOS");
export const create = createRoutine("COURSE/CREATE");
export const uploadImage = createRoutine("COURSE/UPLOAD_IMAGE");
export const addVideo = createRoutine("COURSE/ADD_VIDEO");
export const deleteOne = createRoutine("COURSE/DELETE_ONE");
export const archive = createRoutine("COURSE/ARCHIVE");
export const updateOne = createRoutine("COURSE/UPDATE_ONE");
export const compulsory = createRoutine("COURSE/COMPULSORY");