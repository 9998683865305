import { takeLatest, all, call, put, select } from "redux-saga/effects";

import axios from "axios";
import get from "lodash/get";
import findIndex from "lodash/findIndex";
import _merge from "lodash/merge";
import { BASE_URL } from "constants/variables";

import createRequest from "helpers/createRequest";
import * as courseRoutines from "modules/Course/routines";

import * as routines from "./routines";
import createRequestWithoutLang from "helpers/createRequestWithoutLang";



function* LoadOneAttachment({payload}) {
  try {
    yield put(routines.loadAllFiles.request());

    const response = yield call(createRequest.get, `/units/${payload.unit_id}/materials`);
    const data = response.data.unit_materials;
    yield put(routines.loadAllFiles.success({ data }));
  } catch(error) {
    yield put(routines.loadAllFiles.failure());
  } finally {
    yield put(routines.loadAllFiles.fulfill());
  }
}



function* LoadCommentsWatcher({ payload }) {
  try {
    yield put(routines.loadComments.request());

    const unit_files = yield call(createRequest.get, `/units/${payload.video_id}/materials`);
    yield put(routines.loadComments.success( { data: unit_files.data.unit_materials }));
  } catch (error) {
    yield put(routines.loadComments.failure());
  } finally {
    yield put(routines.loadComments.fulfill());
  }
}

function* ReplyCommentWatcher({ payload: { values, onSuccess = () => {}, onError = () => {} } }) {
  const oldVideoComments = yield select(state => state.Video.comments);

  const oldVideoCommentId = findIndex(oldVideoComments.data, comment => comment.id == values.comment_id);
  let oldVideoComment = oldVideoComments.data[oldVideoCommentId];

  try {
    yield put(routines.replyComment.request());

    const response = yield call(createRequest.post, "/comment-replies", { ...values });

    const { comment_reply } = response.data;

    const newVideoComment = { ...oldVideoComment, replies: [...oldVideoComment.replies, comment_reply] };

    yield put(routines.replyComment.success(newVideoComment));
    yield call(onSuccess);
  } catch (error) {
    yield put(routines.replyComment.failure());
    onError(error);
  } finally {
    yield put(routines.replyComment.fulfill());
  }
}

function* UploadImageWatcher({ payload }) {
  const { file, onSuccess = () => {}, onError = () => {} } = payload;

  try {
    yield put(routines.uploadImage.request());

    const formData = new FormData();

    formData.append("image", file);
    formData.append("_method", "PUT");

    const { data } = yield call(createRequest.post, "/upload/video-image", formData);

    yield put(routines.uploadImage.success(data));
    yield call(onSuccess, data);
  } catch (error) {
    yield put(routines.uploadImage.failure(error));
    yield call(onError, error);
  } finally {
    yield put(routines.uploadImage.fulfill());
  }
}

function* UploadAttachmentWatcher({ payload }) {
  const { file, onSuccess = () => {}, onError = () => {} } = payload;
  try {
    yield put(routines.uploadAttachment.request());
    
    const formData = new FormData();
    formData.append("file", file);
    formData.append("_method", "PUT");
    const { data } = yield call(createRequest.post, `units/${payload.load_url}/materials/files`, formData);
    yield put(routines.uploadAttachment.success(data));

    yield call(onSuccess, data);
  } catch (error) {
    yield put(routines.uploadAttachment.failure(error));
    yield call(onError, error);
  } finally {
    yield put(routines.uploadAttachment.fulfill());
  }
}

function* LoadUploadLinkWatcher({ payload }) {

  try {
    yield put(routines.loadUploadLink.request());

    const response = "";//yield call(createRequest.get, `units/${payload.video_id}/materials`);

    const { link, media } = response.data;

    yield put(routines.loadUploadLink.success({ data: { link, media } }));
  } catch (error) {
    yield put(routines.loadUploadLink.failure());
  } finally {
    yield put(routines.loadUploadLink.fulfill());
  }
}

function* UploadFileWatcher({ payload }) {

  const { file, onSuccess = () => {}, onError = () => {}, onUploadProgress = () => {} } = payload;

  try {
    yield put(routines.uploadFile.request());

    const formData = new FormData();
    formData.append("file", file);

    const { data } = yield call(axios.post, `units/${payload.video_id}/materials/files`, formData, { onUploadProgress });

    yield put(routines.uploadFile.success({ data }));
    yield call(onSuccess, data.media);
  } catch (error) {
    yield put(routines.uploadFile.failure());
    yield call(onError, error);
  } finally {
    yield put(routines.uploadFile.fulfill());
  }
}

function* CreateWatcher({ payload }) {
  const {
    values: { mediaId, ...values },
    onSuccess = () => {},
    onError = () => {}
  } = payload;
  try {
    yield put(routines.create.request());
    const { data } = yield call(createRequestWithoutLang.post, `/modules?_lang=${values.lang.name}`, {
      ...values,
      mediaid: mediaId
    });
    yield put(routines.create.success({ data: data.video }));
    yield put(courseRoutines.addVideo.success(data.video));

    yield call(onSuccess, data.video);
  } catch (error) {
    yield put(routines.create.failure());
    yield call(onError, error);
  } finally {
    yield put(routines.create.fulfill());
  }
}

function* UpdateOneWatcher({ payload }) {
  const { video_id, values, onSuccess = () => {}, onError = () => {} } = payload;

  try {
    yield put(routines.updateOne.request());

    const { data } = yield call(createRequestWithoutLang.patch, `/units/${values.unit}?_lang=${values.lang.name}`, { 
      description: values.description,
      is_archived: false,
      name: values.name,
      teacher_id: values.teacher_id,
     });

    yield put(routines.updateOne.success({ data: data.video }));
    yield put(routines.loadOne.success({ data: data.video }));

    yield call(onSuccess, data.video);
  } catch (error) {
    yield put(routines.updateOne.failure());
    yield call(onError, error);
  } finally {
    yield put(routines.updateOne.fulfill());
  }
}


function* AddAttachmentWatcher({ payload }) {
  const { values, onSuccess = () => {}, onError = () => {} } = payload;
  try {
    yield put(routines.addAttachment.request());
    //function which save uploaded file
    const { data } = yield call(createRequest.post, `units/${payload.unit_id}/materials`, values);
    const video = yield select(state => state.Units.one);
    yield put(
      routines.loadOne.success({
        data: {
          ...video.data,
          references: [...get(video, ["data", "references"], []), data]
        }
      })
    );

    yield call(onSuccess, data);
  } catch (error) {
    yield put(routines.addAttachment.failure());
    yield call(onError, error);
  } finally {
    yield put(routines.addAttachment.fulfill());
  }
}

function* DeleteOneWatcher({ payload }) {
  const { video_id, onSuccess = () => {}, onError = () => {} } = payload;

  try {
    yield put(routines.deleteOne.request());

    yield call(createRequest.delete, `/units/${payload.unit_id}/materials/${payload.unit_material_id}`);

    yield put(routines.deleteOne.success({}));
    yield call(onSuccess);
  } catch (error) {
    yield put(routines.deleteOne.failure());
    yield call(onError, error);
  } finally {
    yield put(routines.deleteOne.fulfill());
  }
}


export default function*() {
  yield all([
    takeLatest(routines.loadAllFiles.TRIGGER, LoadOneAttachment),
    takeLatest(routines.loadComments.TRIGGER, LoadCommentsWatcher),
    takeLatest(routines.replyComment.TRIGGER, ReplyCommentWatcher),
    takeLatest(routines.uploadImage.TRIGGER, UploadImageWatcher),
    takeLatest(routines.uploadAttachment.TRIGGER, UploadAttachmentWatcher),
    takeLatest(routines.loadUploadLink.TRIGGER, LoadUploadLinkWatcher),
    takeLatest(routines.uploadFile.TRIGGER, UploadFileWatcher),
    takeLatest(routines.create.TRIGGER, CreateWatcher),
    takeLatest(routines.updateOne.TRIGGER, UpdateOneWatcher),
    takeLatest(routines.addAttachment.TRIGGER, AddAttachmentWatcher),
    takeLatest(routines.deleteOne.TRIGGER, DeleteOneWatcher)
  ]);
}
