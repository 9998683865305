// @flow
import { CHANGE_LANGUAGE } from "constants/actionTypes";

import language from "constants/language";

const INIT_STATE = {
  language: language.defaultValue
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return { ...state, language: action.payload };

    default:
      return { ...state };
  }
};
