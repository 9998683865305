import { takeLatest, all, call, put, select } from "redux-saga/effects";
import get from "lodash/get";

import createRequest from "helpers/createRequest";

import * as routines from "./routines";
import createRequestWithoutLang from "helpers/createRequestWithoutLang";


function* LoadAllWatcher({ payload }) {
  try {
    yield put(routines.loadAll.request());

    const response = yield call(createRequest.get, `/categories/${payload.category_id}/courses`);

    const data = response.data.courses;
    yield put(routines.loadAll.success({ data }));
  } catch (error) {
    yield put(routines.loadAll.failure());
  } finally {
    yield put(routines.loadAll.fulfill());
  }
}

function* LoadOneWatcher({ payload }) {
  try {
    yield put(routines.loadOne.request());
    const { data } = yield call(createRequest.get, `/courses/${payload.course_id}`);
    yield put(routines.loadOne.success({ data: data.course }));
  } catch (error) {
    yield put(routines.loadOne.failure());
  } finally {
    yield put(routines.loadOne.fulfill());
  }
}

function* LoadVideosWatcher({ payload }) {
  try {
    yield put(routines.loadVideos.request());

    const response = yield call(createRequest.get, `/courses/${payload.course_id}/modules`);
    const data = response.data.modules;
    
    yield put(routines.loadVideos.success({ data }));
  } catch (error) {
    yield put(routines.loadVideos.failure());
  } finally {
    yield put(routines.loadVideos.fulfill());
  }
}

function* CreateWatcher({ payload }) {
  const { values, onSuccess = () => {}, onError = () => {} } = payload;
  
  try {
    yield put(routines.create.request());
    const response = yield call(createRequestWithoutLang.post, `/courses?_lang=${values.lang.name}`, {
        category_id: values.category_id,
        name: values.name,
        description: values.description
    });

    yield put(routines.create.success(response.data.course));
    yield call(onSuccess, response.data.course);
  } catch (error) {
    yield put(routines.create.failure());
    yield call(onError, error);
  } finally {
    yield put(routines.create.fulfill());
  }
}

function* UploadImageWatcher({ payload }) {
  const { file, onSuccess = () => {}, onError = () => {} } = payload;

  try {
    yield put(routines.uploadImage.request());

    const formData = new FormData();

    formData.append("image", file);
    formData.append("_method", "PUT");

    const { data } = yield call(createRequest.post, "/courses/image", formData);

    yield put(routines.uploadImage.success(data));
    yield call(onSuccess, data);
  } catch (error) {
    yield put(routines.uploadImage.failure(error));
    yield call(onError, error);
  } finally {
    yield put(routines.uploadImage.fulfill());
  }
}

function* DeleteOneWatcher({ payload }) {
  const courses = yield select(state => state.Course.all);

  const { course_id, onSuccess = () => {}, onError = () => {} } = payload;
  console.log(payload);
  try {
    yield call(createRequest.delete, `/courses/${course_id}`);

    const newCourses = courses.data.filter(item => item.id != course_id);
    yield put(routines.loadAll({ data: newCourses, meta: courses.meta }));

    yield call(onSuccess);
  } catch (error) {
    yield put(routines.deleteOne.failure(error));
    yield call(onError, error);
  } finally {
    yield put(routines.deleteOne.fulfill());
  }
}

function* ArchiveWatcher({ payload }) {
  const { course_id, is_compulsory } = payload;
  console.log(payload);

  try {
    yield put(routines.compulsory.request());

    const { data } = yield call(createRequest.patch, `/courses/${course_id}/status`, {
      is_compulsory
    });

    yield put(routines.compulsory.success({ data: data.course }));
  } catch (error) {
    yield put(routines.compulsory.failure());
  } finally {
    yield put(routines.compulsory.fulfill());
  }
}

function* UpdateOneWatcher({ payload }) {
  const { course_id, values, onSuccess = () => {}, onError = () => {} } = payload;

  try {
    yield put(routines.updateOne.request());

    const response = yield call(createRequestWithoutLang.patch, `/courses/${values.course_id}?_lang=${values.lang.name}`, { 
      name: values.name,
      price: values.price,
      discount: values.discount,
      description: values.description
     });

    yield put(routines.updateOne.success(response?.data?.course));
    yield call(onSuccess, response?.data?.course);
  } catch (error) {
    yield put(routines.updateOne.failure());
    yield call(onError, error);
  } finally {
    yield put(routines.updateOne.fulfill());
  }
}

export default function*() {
  yield all([
    takeLatest(routines.loadAll.TRIGGER, LoadAllWatcher),
    takeLatest(routines.loadOne.TRIGGER, LoadOneWatcher),
    takeLatest(routines.loadVideos.TRIGGER, LoadVideosWatcher),
    takeLatest(routines.create.TRIGGER, CreateWatcher),
    takeLatest(routines.uploadImage.TRIGGER, UploadImageWatcher),
    takeLatest(routines.deleteOne.TRIGGER, DeleteOneWatcher),
    takeLatest(routines.compulsory.TRIGGER, ArchiveWatcher),
    takeLatest(routines.updateOne.TRIGGER, UpdateOneWatcher)
  ]);
}
