import { takeLatest, all, call, put, select } from "redux-saga/effects";
import get from "lodash/get";

import createRequest from "helpers/createRequest";

import * as routines from "./routines";
import createRequestWithoutLang from "helpers/createRequestWithoutLang";
import { slice } from "lodash";

function* LoadAllWatcher({ payload }) {
  try {
    yield put(routines.loadAll.request());


    const response = yield call(createRequest.get, "/test/questions",{
      params: {
        type: 'unit',
        course_id: payload.course_id,
        unit_id: payload.unit_id,
        module_id: '',
        page: payload.page
      }
    }
    );

    const data = response.data.test_questions;

    yield put(routines.loadAll.success({ data }));
  } catch (error) {
    yield put(routines.loadAll.failure());
  } finally {
    yield put(routines.loadAll.fulfill());
  }
}

function* LoadOneWatcher({ payload }) {

  try {
    yield put(routines.loadOne.request());
    const { data } = yield call(createRequest.get, `/test/questions/${payload.question_id}/options`);

    yield put(routines.loadOne.success({ data: data.test_options }));
  } catch (error) {
    yield put(routines.loadOne.failure());
  } finally {
    yield put(routines.loadOne.fulfill());
  }
}

function* DisplayOneWatcher({ payload }) {

  try {
    yield put(routines.displayOne.request());
    const { data } = yield  call(createRequest.get, `test/questions/${payload.question_id}`);
    yield put(routines.displayOne.success({ data: data.test_question }));
  } catch (error) {
    yield put(routines.displayOne.failure());
  }finally {
    yield put(routines.displayOne.fulfill());
  }
}

function* UpdateWatcher({ payload }) {
  const { onSuccess = () => {}, onError = () => {} } = payload.values;

  try {
    yield put(routines.update.request());
    const response = yield call(createRequestWithoutLang.patch, `/test/questions/${payload.values.question_id}?_lang=${payload.values.lang.name}`, {
      name: payload.values.name,
      options: payload.values.options
    });

    yield put(routines.update.success(response.data.tests));
    yield call(onSuccess, response.data.tests);
  } catch (error) {
    yield put(routines.update.failure());
    yield call(onError, error);
  } finally {
    yield put(routines.update.fulfill());
  }
}

function* UpdateOneWatcher({ payload }) {
  const { option_id, values, onSuccess = () => {}, onError = () => {} } = payload;

  try {
    yield put(routines.updateOne.request());
    const response = yield call(createRequestWithoutLang.patch, `/test/options/${option_id}`, { ...values });

    yield put(routines.updateOne.success(response.data.options));
    yield call(onSuccess, response.data.options);
  } catch (error) {
    yield put(routines.updateOne.failure());
    yield call(onError, error);
  } finally {
    yield put(routines.updateOne.fulfill());
  }
}


function* CreateWatcher({ payload }) {
  const { values, onSuccess = () => {}, onError = () => {} } = payload;
  
  try {
    yield put(routines.create.request());
    const response = yield call(createRequestWithoutLang.post, `/test/questions?_lang=${values.lang.name}`, { ...values });

    yield put(routines.create.success(response.data.test));
    yield call(onSuccess, response.data.test);
  } catch (error) {
    yield put(routines.create.failure());
    yield call(onError, error);
  } finally {
    yield put(routines.create.fulfill());
  }
}

function* DeleteOneWatcher({ payload }) {
  const tests = yield select(state => state.Test.all);

  const { test_id, onSuccess = () => {}, onError = () => {} } = payload;

  try {
    yield call(createRequest.delete, `/test/questions/${test_id}`);

    const newTests = (tests?.data || []).filter(test => test.id != test_id);
    yield put(routines.loadAll.success({ data: newTests, meta: tests?.meta }));

    yield call(onSuccess);
  } catch (error) {
    yield put(routines.deleteOne.failure(error));
    yield call(onError, error);
  } finally {
    yield put(routines.deleteOne.fulfill());
  }
}


export default function*() {
  yield all([
    takeLatest(routines.loadAll.TRIGGER, LoadAllWatcher),
    takeLatest(routines.loadOne.TRIGGER, LoadOneWatcher),
    takeLatest(routines.displayOne.TRIGGER, DisplayOneWatcher),
    takeLatest(routines.update.TRIGGER, UpdateWatcher),
    takeLatest(routines.updateOne.TRIGGER, UpdateOneWatcher),
    takeLatest(routines.create.TRIGGER, CreateWatcher),
    takeLatest(routines.deleteOne.TRIGGER, DeleteOneWatcher)
  ])
}