import { takeLatest, all, call, put } from "redux-saga/effects";
import { authRequest } from "helpers/createRequest";
import cookies from "services/cookies";
import * as routines from "./routines";



function* LoginWatcher({ payload }) {
  const { values, onSuccess = () => {}, onError = () => {} } = payload;

  try {
    const { email, password, session_id, user_agent, device_type, browser, browser_version } = values;

    yield put(routines.login.request());

    const response = yield call(authRequest.post, "/auth/login", {
      email,
      password,
      session_id,
      user_agent,
      device_type,
      browser,
      browser_version
    });

    const { user_role, access_token, user, user_permissions } = response.data;

    cookies.set("access_token", access_token, { path: "/" });
    cookies.set("user", JSON.stringify(user), { path: "/" });
    cookies.set("session", JSON.stringify(user_role.id), { path: "/" });
    
    yield put(
      routines.login.success({
        access_token,
        user_role,
        user
      })
    );
    yield call(onSuccess);
  } catch (error) {
    yield put(routines.login.failure());
    if (error?.response) {
      yield call(onError, error.response);
    }
  } finally {
    yield put(routines.login.fulfill());
  }
}

function* LogoutWatcher({ payload }) {
  cookies.remove("access_token", { path: "/" });
  cookies.remove("user", { path: "/" });
  cookies.remove("session", { path: "/" });
  window.location.reload();
}

function* RegisterWatcher({ payload }) {
  const { values, onSuccess = () => {}, onError = () => {} } = payload;

  try {
    const { organization_id, name, phone_number, email, password, password_confirmation, passport_no, passport_issuer, address_living, address_register, address_working } = values;

    yield put(routines.register.request());

    const response = yield call(authRequest.post, "/auth/register", {
      organization_id,
      name,
      phone_number,
      email,
      password,
      password_confirmation,
      passport_no,
      passport_issuer,
      address_living,
      address_register,
      address_working
    });

    const {} = response.data;

    yield put(routines.register.success({ data: {} }));
    yield call(onSuccess);
  } catch (error) {
    yield put(routines.register.failure());
    if (error.response) {
      yield call(onError, error.response);
    }
  } finally {
    yield put(routines.register.fulfill());
  }
}

function* ConfirmPhoneWatcher({ payload }) {
  const { values, onSuccess = () => {}, onError = () => {} } = payload;

  try {
    const { phone_number, token } = values;

    yield put(routines.confirmPhone.request());

    const response = yield call(authRequest.post, "/auth/register/confirm", {
      phone_number,
      token
    });

    const {} = response.data;

    yield put(routines.confirmPhone.success({ data: {} }));
    yield call(onSuccess);
  } catch (error) {
    yield put(routines.confirmPhone.failure());
    yield call(onError, error);
  } finally {
    yield put(routines.confirmPhone.fulfill());
  }
}

function* ValidatePhoneWatcher({ payload }) {
  const { values, onSuccess = () => {}, onError = () => {} } = payload;

  try {
    const { phone_number } = values;

    yield put(routines.validatePhone.request());

    const response = yield call(authRequest.get, "/auth/register/validate", {
      params: {
        phone_number
      }
    });

    const {} = response.data;

    yield put(routines.validatePhone.success({ data: {} }));
    yield call(onSuccess);
  } catch (error) {
    yield put(routines.validatePhone.failure());
    yield call(onError, error);
  } finally {
    yield put(routines.validatePhone.fulfill());
  }
}


function* SendCodeWatcher({ payload }) {
  const { values, onSuccess = () => {}, onError = () => {} } = payload;

  try {
    const { phone_number } = values;

    yield put(routines.sendCode.request());

    const response = yield call(authRequest.post, "/auth/password/send", {
      phone_number
    });

    yield put(routines.sendCode.success({ data: {} }));
    yield call(onSuccess, response.data);
  } catch (error) {
    yield put(routines.sendCode.failure());
    if (error.response) {
      yield call(onError, error.response);
    }
  } finally {
    yield put(routines.sendCode.fulfill());
  }
}

function* VerifyCodeWatcher({ payload }) {
  const { values, onSuccess = () => {}, onError = () => {} } = payload;

  try {
    const { phone_number, code } = values;

    yield put(routines.verifyCode.request());

    const response = yield call(authRequest.post, "/auth/password/verify", {
      phone_number,
      token: code
    });

    yield put(routines.verifyCode.success({ data: {} }));
    yield call(onSuccess, response.data);
  } catch (error) {
    yield put(routines.verifyCode.failure());
    if (error.response) {
      yield call(onError, error.response);
    }
  } finally {
    yield put(routines.verifyCode.fulfill());
  }
}

function* ResetPasswordWatcher({ payload }) {
  const { values, onSuccess = () => {}, onError = () => {} } = payload;

  try {
    const { user_id, temp_password, password, password_confirmation } = values;

    yield put(routines.resetPassword.request());

    const response = yield call(authRequest.post, "/auth/password/reset", {
      user_id,
      temp_password,
      password,
      password_confirmation
    });

    yield put(routines.resetPassword.success({ data: {} }));
    yield call(onSuccess);
  } catch (error) {
    yield put(routines.resetPassword.failure());
    if (error.response) {
      yield call(onError, error.response);
    }
  } finally {
    yield put(routines.resetPassword.fulfill());
  }
}


export default function*() {
  yield all([
    takeLatest(routines.login.TRIGGER, LoginWatcher),
    takeLatest(routines.logout.TRIGGER, LogoutWatcher),
    takeLatest(routines.register.TRIGGER, RegisterWatcher),
    takeLatest(routines.confirmPhone.TRIGGER, ConfirmPhoneWatcher),
    takeLatest(routines.validatePhone.TRIGGER, ValidatePhoneWatcher),
    takeLatest(routines.sendCode.TRIGGER, SendCodeWatcher),
    takeLatest(routines.verifyCode.TRIGGER, VerifyCodeWatcher),
    takeLatest(routines.resetPassword.TRIGGER, ResetPasswordWatcher)
  ]);
}
