import createReducerFactory from "helpers/createReducerFactory";
import createReducerState from "helpers/createReducerState";
import createFormReducer from "helpers/createFormReducer";
import defaultState from "constants/defaultState";

import { loadAll, loadOne, displayOne, update, updateOne, create, deleteOne } from "./routines";

const initialState = {
  all: defaultState,
  one: defaultState,
  displayOne: defaultState,
  update: defaultState,
  updateOne: defaultState,
  create: defaultState,
  deleteOne: defaultState
};

const factories = {
  ...createReducerState(loadAll, "all"),
  ...createReducerState(loadOne, "one"),
  ...createReducerState(displayOne, "displayOne"),
  ...createReducerState(update, "update"),
  ...createReducerState(updateOne, "updateOne"),
  ...createFormReducer(create, "create"),
  ...createReducerState(deleteOne, "deleteOne")
};

export default createReducerFactory(initialState, factories);
