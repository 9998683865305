import createReducerFactory from "helpers/createReducerFactory";
import createReducerState from "helpers/createReducerState";
import defaultState from "constants/defaultState";

import { loadAll, loadOne, loadCompletedCourses, loadSubscriptions, loadSessions, active, userOrders, documents, approve, certificate } from "./routines";

const initialState = {
  all: defaultState,
  one: defaultState,
  completedCourses: defaultState,
  subscriptions: defaultState,
  sessions: defaultState,
  active: defaultState,
  orders: defaultState,
  document: defaultState,
  approve: defaultState,
  certificate: defaultState
};

const factories = {
  ...createReducerState(loadAll, "all"),
  ...createReducerState(loadOne, "one"),
  ...createReducerState(loadCompletedCourses, "completedCourses"),
  ...createReducerState(loadSubscriptions, "subscriptions"),
  ...createReducerState(loadSessions, "sessions"),
  ...createReducerState(active, "active"),
  ...createReducerState(userOrders, "orders"),
  ...createReducerState(documents, "document"),
  ...createReducerState(approve, "approve"),
  ...createReducerState(certificate, "certificate")
};

export default createReducerFactory(initialState, factories);
