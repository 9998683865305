import { takeLatest, all, call, put } from "redux-saga/effects";

import createRequest from "helpers/createRequest";

import * as routines from "./routines";

function* LoadAllWatcher({ payload }) {
  try {
    yield put(routines.loadAll.request());

    const response = yield call(createRequest.get, "/roles", {
      params: {}
    });

    const data = response.data.roles;

    yield put(routines.loadAll.success({ data }));
  } catch (error) {
    yield put(routines.loadAll.failure());
  } finally {
    yield put(routines.loadAll.fulfill());
  }
}
function* LoadOneWatcher() {
  yield put(routines.loadOne.request({}));
}

function* UpdateWatcher({ payload }) {
  const { role_id, values, onSuccess = () => {}, onError = () => {} } = payload;

  try {
    yield put(routines.update.request());

    const response = yield call(createRequest.patch, `/roles/${role_id}`, { ...values });

    yield put(routines.update.success(response.data.role));
    yield call(onSuccess);
  } catch (error) {
    yield put(routines.update.failure());
    yield call(onError, error);
  } finally {
    yield put(routines.update.fulfill());
  }
}

export default function*() {
  yield all([
    takeLatest(routines.loadAll.TRIGGER, LoadAllWatcher),
    takeLatest(routines.loadOne.TRIGGER, LoadOneWatcher),
    takeLatest(routines.update.TRIGGER, UpdateWatcher)
  ]);
}
