import { createRoutine } from "redux-saga-routines";

export const loadAll = createRoutine("USER/LOAD_ALL");
export const loadOne = createRoutine("USER/LOAD_ONE");
export const loadCompletedCourses = createRoutine("USER/LOAD_COMPLETED_COURSES");
export const loadSubscriptions = createRoutine("USER/LOAD_SUBSCRIPTIONS");
export const loadSessions = createRoutine("USER/LOAD_SESSIONS");
export const active = createRoutine("USER/ACTIVE");
export const userOrders = createRoutine("USER/ORDERS");
export const documents = createRoutine("USER/DOCUMENTS");
export const approve = createRoutine("USER/APPROVE");
export const certificate = createRoutine("USER/CERTIFICATE");