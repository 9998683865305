import { all, fork } from "redux-saga/effects";

import authSagas from "modules/Auth/sagas";
import userSagas from "modules/User/sagas";
import adminSagas from "modules/Admin/sagas";
import teacherSagas from "modules/Teacher/sagas";
import roleSagas from "modules/Role/sagas";
import studentSagas from "modules/Student/sagas";
import courseSagas from "modules/Course/sagas";
import categorySagas from "modules/Category/sagas";
import subscriptionSagas from "modules/Subscription/sagas";
import transactionSagas from "modules/Transaction/sagas";
import permissionSagas from "modules/Permission/sagas";
import videoSagas from "modules/Video/sagas";
import translationSagas from "modules/Translation/sagas";
import assetSagas from "modules/Asset/sagas";
import statisticsSagas from "modules/Statistics/sagas";
import questingsSagas from "modules/Questions/sagas";
import testSagas from "modules/Test/sagas";
import unitSagas from "modules/Units/sagas";
import testsSages from "modules/Tests/sagas";
import profileSagas from "modules/Profile/sagas";
import teacherProfileSagas from "modules/TeacherPanel/sagas";
import Unittest from "modules/Unittest/sagas";
import organisationsSagas from "modules/Organisation/sagas";

export default function* rootSaga(getState) {
  yield all([
    fork(authSagas),
    fork(userSagas),
    fork(adminSagas),
    fork(teacherSagas),
    fork(roleSagas),
    fork(studentSagas),
    fork(courseSagas),
    fork(categorySagas),
    fork(questingsSagas),
    fork(subscriptionSagas),
    fork(transactionSagas),
    fork(teacherProfileSagas),
    fork(permissionSagas),
    fork(videoSagas),
    fork(unitSagas),
    fork(Unittest),
    fork(profileSagas),
    fork(translationSagas),
    fork(assetSagas),
    fork(statisticsSagas),
    fork(testSagas),
    fork(testsSages),
    fork(organisationsSagas)
  ]);
}
