import createReducerFactory from "helpers/createReducerFactory";
import createReducerState from "helpers/createReducerState";
import createFormReducer from "helpers/createFormReducer";
import defaultState from "constants/defaultState";

import { entranceTest, loadOne, saveOne, finishTest } from "./routines";

const initialState = {
  entranceTest: defaultState,
  loadOne: defaultState,
  saveOne: defaultState,
  finishTest: defaultState
};

const factories = {
  ...createReducerState(entranceTest, "entranceTest"),
  ...createReducerState(loadOne, "loadOne"),
  ...createReducerState(saveOne, "saveOne"),
  ...createReducerState(finishTest, "finishTest")
};

export default createReducerFactory(initialState, factories);
