import { lazy } from "react";

const Login = lazy(() => import("pages/Auth/Login"));
const Logout = lazy(() => import("pages/Auth/Logout"));
const ForgetPassword = lazy(() => import("pages/Auth/ForgetPassword"));
const Confirm = lazy(() => import("pages/Auth/Confirm"));
const Registration = lazy(() => import("pages/Student"));
const Reset = lazy(() => import("pages/Auth/Reset"));

const Dashboard = lazy(() => import("pages/Dashboard"));
const Panel = lazy(() => import("pages/Panel"));
const Teacher = lazy(() => import("pages/Teacher"));

const routes = [
  { path: "/login", component: Login },
  { path: "/logout", component: Logout },
  { path: "/panel", component: Panel },
  { path: "/teacher", component: Teacher },
  { path: "/student/registration", component: Registration },
  { path: "/forget-password", component: ForgetPassword },
  { path: "/confirm", component: Confirm },
  { path: "/reset", component: Reset },

  { path: "/dashboard", component: Dashboard },

  { path: "/", exact: true, component: Login }
];

export default routes;
