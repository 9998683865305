import createReducerFactory from "helpers/createReducerFactory";
import createReducerState from "helpers/createReducerState";
import defaultState from "constants/defaultState";

import { loadAll, loadOne } from "./routines";

const initialState = {
  all: defaultState,
  one: defaultState
};

const factories = {
  ...createReducerState(loadAll, "all"),
  ...createReducerState(loadOne, "one")
};

export default createReducerFactory(initialState, factories);
