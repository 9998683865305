import { createRoutine } from "redux-saga-routines";


export const loadAll = createRoutine("QUESTIONS/LOAD_ALL");
export const loadOne = createRoutine("QUESTIONS/LOAD_ONE");
export const loadModule = createRoutine("QUESTIONS/LOAD_MODULE");
export const loadUnit = createRoutine("QUESTIONS/LOAD_UNITS");
export const loadUnitMaterials = createRoutine("QUESTIONS/LOAD_UNIT_MATERIALS");
export const loadUnitTest = createRoutine("QUESTIONS/LOAD_UNIT_TEST");
export const loadUnitTestOption = createRoutine("QUESTIONS/LOAD_UNIT_OPTION");
export const moduleTest = createRoutine("QUESTIONS/LOAD_MODULE_TEST");
export const moduleTestOne = createRoutine("QUESTIONS/LOAD_MODULE_TEST_ONE");
export const moduleTestSave = createRoutine("QUESTIONS/LOAD_MODULE_TEST_SAVE");
export const moduleTestFinish = createRoutine("QUESTIONS/LOAD_MODULE_TEST_FINISH");
export const courseTest = createRoutine("QUESTIONS/LOAD_COURSE_TEST");
export const courseTestOne = createRoutine("QUESTIONS/LOAD_COURSE_TEST_ONE");
export const courseTestSave = createRoutine("QUESTIONS/LOAD_COURSE_TEST_SAVE");
export const courseTestFinish = createRoutine("QUESTIONS/LOAD_COURSE_TEST_FINISH");