import { combineReducers } from "redux";

import System from "./system/reducers";
import Auth from "modules/Auth/reducer";
import User from "modules/User/reducer";
import Admin from "modules/Admin/reducer";
import Teacher from "modules/Teacher/reducer";
import Role from "modules/Role/reducer";
import Course from "modules/Course/reducer";
import Organisation from "modules/Organisation/reducer";
import Student from "modules/Student/reducer";
import Questions from "modules/Questions/reducer";
import Category from "modules/Category/reducer";
import Subscription from "modules/Subscription/reducer";
import Transaction from "modules/Transaction/reducer";
import Profile from "modules/Profile/reducer";
import Test from "modules/Test/reducer";
import Tests from "modules/Tests/reducer";
import Permission from "modules/Permission/reducer";
import Video from "modules/Video/reducer";
import TeacherProfile from "modules/TeacherPanel/reducer";
import Unittest from "modules/Unittest/reducer";
import Units from "modules/Units/reducer";
import Translation from "modules/Translation/reducer";
import Asset from "modules/Asset/reducer";
import Statistics from "modules/Statistics/reducer";

export default combineReducers({
  System,
  Auth,
  User,
  Admin,
  Teacher,
  Role,
  Unittest,
  Organisation,
  Questions,
  Student,
  Course,
  Units,
  Category,
  Subscription,
  Test,
  TeacherProfile,
  Tests,
  Profile,
  Transaction,
  Permission,
  Video,
  Translation,
  Asset,
  Statistics
});
