import { takeLatest, all, call, put, select } from "redux-saga/effects";
import get from "lodash/get";

import createRequest, { authRequest } from "helpers/createRequest";

import * as routines from "./routines";
import { slice, take } from "lodash";
import cookies from "services/cookies";

function* LoadAllWatcher({ payload }) {
  
  const access_token = cookies.get('access_token');
  try {
    yield put(routines.entranceTest.request());
    const response = yield call(authRequest.post, `student/tests/entrance/start?_lang=ru&access_token=${access_token}`,{
      course_id: payload.course_id
    });


    const data = response.data.question_ids;
    const meta = response.data.test;
    yield put(routines.entranceTest.success({ data, meta }));
  } catch (error) {
    yield put(routines.entranceTest.failure());
  } finally {
    yield put(routines.entranceTest.fulfill());
  }
}

function* LoadOneWatcher({ payload }) {
  const access_token = cookies.get('access_token');
  try {
    yield put(routines.loadOne.request());
    const response = yield call(authRequest.get, `student/tests/entrance/questions/${payload}?_lang=ru&access_token=${access_token}`);


    const data = response.data.test_question.options;
    const meta = response.data.test_question;
    yield put(routines.loadOne.success({ data, meta }));
  } catch (error) {
    yield put(routines.loadOne.failure());
  } finally {
    yield put(routines.loadOne.fulfill());
  }
}

function* SaveOneWatcher({ payload }) {
  const access_token = cookies.get('access_token');
  const { values, onSuccess = () => {}, onError = () => {} } = payload;
  try {
    yield put(routines.saveOne.request());
    const response = yield call(authRequest.post, `student/tests/entrance/save?access_token=${access_token}`, {
      ...values
    });


    const data = response.data.message;
    const meta = response.data.status;
    yield put(routines.saveOne.success({ data, meta }));
  } catch (error) {
    yield put(routines.saveOne.failure());
  } finally {
    yield put(routines.saveOne.fulfill());
  }
}


function* FinishTestWatcher({ payload }) {
  const access_token = cookies.get('access_token');
  const test_id = payload.id
  try {
    yield put(routines.finishTest.request());
    const response = yield call(authRequest.post, `student/tests/entrance/finish?access_token=${access_token}`, {
      test_id: test_id
    });


    const data = response.data;
    const meta = response.data.status;
    yield put(routines.finishTest.success({ data, meta }));
  } catch (error) {
    yield put(routines.finishTest.failure());
  } finally {
    yield put(routines.finishTest.fulfill());
  }
}



export default function*() {
  yield all([
    takeLatest(routines.entranceTest.TRIGGER, LoadAllWatcher),
    takeLatest(routines.loadOne.TRIGGER, LoadOneWatcher),
    takeLatest(routines.saveOne.TRIGGER, SaveOneWatcher),
    takeLatest(routines.finishTest.TRIGGER, FinishTestWatcher)
  ])
}