import { takeLatest, all, call, put, select } from "redux-saga/effects";

import { authRequest } from "helpers/createRequest";

import * as routines from "./routines";
import cookies from "services/cookies";
import { take } from "lodash";

const access_token = cookies.get('access_token');
const _lang = localStorage.getItem('language');
function* LoadAllWatcher({ payload }) {
  try {
    yield put(routines.loadAll.request());

    const cat_id = payload.category_id;
    const response = yield call(authRequest.get, `student/courses?_lang=${_lang}&access_token=${access_token}`, {
        params: {
          category_id: payload.category_id,
          page: payload.page
        }
      });
    const data = response.data.courses;
    yield put(routines.loadAll.success({ data }));
  } catch (error) {
    yield put(routines.loadAll.failure());
  } finally {
    yield put(routines.loadAll.fulfill());
  }
}

function* LoadModuleWatcher({ payload }) {
  try {
    yield put(routines.loadModule.request());
    const { data } = yield call(authRequest.get, `/student/courses/${payload.course_id}/modules?_lang=${_lang}`);
    yield put(routines.loadModule.success({ data: data }));
  } catch (error) {
    yield put(routines.loadModule.failure());
  } finally {
    yield put(routines.loadModule.fulfill());
  }
}

function* LoadOneWatcher({ payload }) {
  try {
    yield put(routines.loadOne.request());
    const response = yield call(authRequest.get, `/student/courses/${payload.course_id}/modules?_lang=${_lang}&access_token=${access_token}`);
    const data = response.data.modules
    yield put(routines.loadOne.success({ data: data }));
  } catch (error) {
    yield put(routines.loadOne.failure());
  } finally {
    yield put(routines.loadOne.fulfill());
  }
}

function* LoadUnitWatcher({ payload }) {
  try {
    yield put(routines.loadUnit.request());
    const response = yield call(authRequest.get, `/student/modules/${payload.module_id}/units?_lang=${_lang}&access_token=${access_token}`);
    const data = response.data.units
    yield put(routines.loadUnit.success({ data: data }));
  } catch (error) {
    yield put(routines.loadUnit.failure());
  } finally {
    yield put(routines.loadUnit.fulfill());
  }
}


function* LoadUnitMaterialWatcher({ payload }) {
  try {
    yield put(routines.loadUnitMaterials.request());
    const response = yield call(authRequest.get, `/student/units/${payload.unit_id}/materials?_lang=${_lang}&access_token=${access_token}`);
    const data = response.data.unit_materials
    yield put(routines.loadUnitMaterials.success({ data: data }));
  } catch (error) {
    yield put(routines.loadUnitMaterials.failure());
  } finally {
    yield put(routines.loadUnitMaterials.fulfill());
  }
}

function* LoadUnitTest({ payload }) {
  try {
    yield put(routines.loadUnitTest.request());
    const response = yield call(authRequest.get, `student/units/${payload.unit_id}/questions?_lang=${_lang}&access_token=${access_token}`);
    const data = response.data.questions
    yield put(routines.loadUnitTest.success({ data: data }));
  } catch (error) {
    yield put(routines.loadUnitTest.failure());
  } finally {
    yield put(routines.loadUnitTest.fulfill());
  }
}

function* LoadUnitTestOption({ payload }) {
  const values = payload.values;
  const access_token = cookies.get('access_token');
  try {
    yield put(routines.loadUnitTestOption.request());
    const response = yield call(authRequest.post, `student/units/${payload.values.unit_id}/questions?_lang=${_lang}&access_token=${access_token}`,{
      attempt_no: payload.values.attempt_no,
      question_id: payload.values.question_id
    });


    const data = response.data;
    yield put(routines.loadUnitTestOption.success({ data }));
  } catch (error) {
    yield put(routines.loadUnitTestOption.failure());
  } finally {
    yield put(routines.loadUnitTestOption.fulfill());
  }
}


function* LoadModuleTest({ payload }) {
  const access_token = cookies.get('access_token');
  try {
    yield put(routines.moduleTest.request());
    const response = yield call(authRequest.post, `student/tests/module/start?_lang=${_lang}&access_token=${access_token}`,{
      module_id: payload.module_id
    });


    const data = response.data.question_ids;
    const meta = response.data.test;
    yield put(routines.moduleTest.success({ data, meta }));
  } catch (error) {
    yield put(routines.moduleTest.failure());
  } finally {
    yield put(routines.moduleTest.fulfill());
  }
}


function* LoadModuleTestOne({ payload }) {
  const access_token = cookies.get('access_token');
  try {
    yield put(routines.moduleTestOne.request());
    const response = yield call(authRequest.get, `student/tests/module/questions/${payload}?_lang=${_lang}&access_token=${access_token}`);


    const data = response.data.test_question.options;
    const meta = response.data.test_question;
    yield put(routines.moduleTestOne.success({ data, meta }));
  } catch (error) {
    yield put(routines.moduleTestOne.failure());
  } finally {
    yield put(routines.moduleTestOne.fulfill());
  }
}

function* LoadModuleTestSave({ payload }) {
  const access_token = cookies.get('access_token');
  const { values, onSuccess = () => {}, onError = () => {} } = payload;
  try {
    yield put(routines.moduleTestSave.request());
    const response = yield call(authRequest.post, `student/tests/module/save?access_token=${access_token}`, {
      ...values
    });


    const data = response.data.message;
    const meta = response.data.status;
    yield put(routines.moduleTestSave.success({ data, meta }));
  } catch (error) {
    yield put(routines.moduleTestSave.failure());
  } finally {
    yield put(routines.moduleTestSave.fulfill());
  }
}


function* LoadModuleTestFinish({ payload }) {
  const access_token = cookies.get('access_token');
  const test_id = payload.id
  try {
    yield put(routines.moduleTestFinish.request());
    const response = yield call(authRequest.post, `student/tests/module/finish?access_token=${access_token}`, {
      test_id: test_id
    });


    const data = response.data;
    const meta = response.data.status;
    yield put(routines.moduleTestFinish.success({ data, meta }));
  } catch (error) {
    yield put(routines.moduleTestFinish.failure());
  } finally {
    yield put(routines.moduleTestFinish.fulfill());
  }
}


function* LoadCourseTest({ payload }) {
  const access_token = cookies.get('access_token');
  try {
    yield put(routines.courseTest.request());
    const response = yield call(authRequest.post, `student/tests/final/start?_lang=${_lang}&access_token=${access_token}`,{
      course_id: payload.course_id
    });


    const data = response.data.question_ids;
    const meta = response.data.test;
    yield put(routines.courseTest.success({ data, meta }));
  } catch (error) {
    yield put(routines.courseTest.failure());
  } finally {
    yield put(routines.courseTest.fulfill());
  }
}


function* LoadCourseTestOne({ payload }) {
  const access_token = cookies.get('access_token');
  try {
    yield put(routines.courseTestOne.request());
    const response = yield call(authRequest.get, `student/tests/final/questions/${payload}?_lang=${_lang}&access_token=${access_token}`);


    const data = response.data.test_question.options;
    const meta = response.data.test_question;
    yield put(routines.courseTestOne.success({ data, meta }));
  } catch (error) {
    yield put(routines.courseTestOne.failure());
  } finally {
    yield put(routines.courseTestOne.fulfill());
  }
}

function* LoadCourseTestSave({ payload }) {
  const access_token = cookies.get('access_token');
  const { values, onSuccess = () => {}, onError = () => {} } = payload;
  try {
    yield put(routines.courseTestSave.request());
    const response = yield call(authRequest.post, `student/tests/final/save?access_token=${access_token}`, {
      ...values
    });


    const data = response.data.message;
    const meta = response.data.status;
    yield put(routines.courseTestSave.success({ data, meta }));
  } catch (error) {
    yield put(routines.courseTestSave.failure());
  } finally {
    yield put(routines.courseTestSave.fulfill());
  }
}


function* LoadCourseTestFinish({ payload }) {
  const access_token = cookies.get('access_token');
  const test_id = payload.id
  try {
    yield put(routines.courseTestFinish.request());
    const response = yield call(authRequest.post, `student/tests/final/finish?access_token=${access_token}`, {
      test_id: test_id
    });


    const data = response.data;
    const meta = response.data.status;
    yield put(routines.courseTestFinish.success({ data, meta }));
  } catch (error) {
    yield put(routines.courseTestFinish.failure());
  } finally {
    yield put(routines.courseTestFinish.fulfill());
  }
}

export default function*() {
  yield all([
    takeLatest(routines.loadAll.TRIGGER, LoadAllWatcher),
    takeLatest(routines.loadOne.TRIGGER, LoadOneWatcher),
    takeLatest(routines.loadModule.TRIGGER, LoadModuleWatcher),
    takeLatest(routines.loadUnit.TRIGGER, LoadUnitWatcher),
    takeLatest(routines.loadUnitMaterials.TRIGGER, LoadUnitMaterialWatcher),
    takeLatest(routines.loadUnitTest.TRIGGER, LoadUnitTest),
    takeLatest(routines.loadUnitTestOption.TRIGGER, LoadUnitTestOption),
    takeLatest(routines.moduleTest.TRIGGER, LoadModuleTest),
    takeLatest(routines.moduleTestOne.TRIGGER, LoadModuleTestOne),
    takeLatest(routines.moduleTestSave.TRIGGER, LoadModuleTestSave),
    takeLatest(routines.moduleTestFinish.TRIGGER, LoadModuleTestFinish),
    takeLatest(routines.courseTest.TRIGGER, LoadCourseTest),
    takeLatest(routines.courseTestOne.TRIGGER, LoadCourseTestOne),
    takeLatest(routines.courseTestSave.TRIGGER, LoadCourseTestSave),
    takeLatest(routines.courseTestFinish.TRIGGER, LoadCourseTestFinish)
  ]);
}
