import createReducerFactory from "helpers/createReducerFactory";
import createReducerState from "helpers/createReducerState";
import defaultState from "constants/defaultState";

import { info, students, loadOne, allowTest } from "./routines";

const initialState = {
  info: defaultState,
  students: defaultState,
  loadOne: defaultState,
  allowTest: defaultState
};

const factories = {
  ...createReducerState(info, "info"),
  ...createReducerState(students, "students"),
  ...createReducerState(loadOne, "loadOne"),
  ...createReducerState(allowTest, "allowTest")

};

export default createReducerFactory(initialState, factories);
