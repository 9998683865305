import { takeLatest, all, call, put } from "redux-saga/effects";
import _get from "lodash/get";

import createRequest from "helpers/createRequest";

import * as routines from "./routines";

function* LoadTopVideosWatcher({ payload }) {
  const { params: { start_date, end_date } = {} } = payload;

  try {
    yield put(routines.loadTopVideos.request());

    const { data } = yield call(createRequest.get, "/dashboard/statistics", {
      params: { start_date, end_date }
    });

    yield put(routines.loadTopVideos.success({ data }));
  } catch (error) {
    yield put(routines.loadTopVideos.failure());
  } finally {
    yield put(routines.loadTopVideos.fulfill());
  }
}

function* LoadTopUsersWatcher({ payload }) {

  try {
    yield put(routines.loadTopUsers.request());

    const response = yield call(createRequest.get, "/dashboard/users", {
      params: {
        sort: 'asc',
        limit: 50
      }
    });

    yield put(
      routines.loadTopUsers.success({
        data: _get(response, ["data", "users"], [])
      })
    );
  } catch (error) {
    yield put(routines.loadTopUsers.failure());
  } finally {
    yield put(routines.loadTopUsers.fulfill());
  }
}

function* LoadVideoInfoByMediaIdWatcher({ payload }) {
  const { mediaId, onSuccess = () => {}, onError = () => {} } = payload;

  try {
    yield put(routines.loadVideoInfoByMediaId.request());

    const { data } = yield call(createRequest.get, "/dashboard/videos/show", {
      params: {
        media_id: mediaId
      }
    });

    yield put(routines.loadVideoInfoByMediaId.success({ data: data?.video }));
    yield call(onSuccess, data.video);
  } catch (error) {
    yield put(routines.loadVideoInfoByMediaId.failure());
    yield call(onError, error);
  } finally {
    yield put(routines.loadVideoInfoByMediaId.fulfill());
  }
}

export default function*() {
  yield all([
    takeLatest(routines.loadTopVideos.TRIGGER, LoadTopVideosWatcher),
    takeLatest(routines.loadTopUsers.TRIGGER, LoadTopUsersWatcher),
    takeLatest(routines.loadVideoInfoByMediaId.TRIGGER, LoadVideoInfoByMediaIdWatcher)
  ]);
}
