import { createRoutine } from "redux-saga-routines";

export const profile = createRoutine("STUDENT/PROFILE");
export const orders = createRoutine("STUDENT/ORDERS");
export const uploadDocs = createRoutine("STUDENT/UPLOAD_DOCS");
export const update = createRoutine("STUDENT/UPDATE");
export const updateInfo = createRoutine("STUDENT/UPDATE_INFO");
export const uploadImage = createRoutine("STUDENT/UPLOAD_IMAGE");
export const documents = createRoutine("STUDENT/DOCUMENTS");
export const activity = createRoutine("AUTH/ACTIVITY");
