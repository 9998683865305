import { createStore, applyMiddleware, compose } from "redux";

import reducers from "./reducers";
import sagas from "./sagas";
import middlewares, { sagaMiddleware } from "./middlewares";

export function configureStore(initialState) {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(reducers, initialState, composeEnhancers(applyMiddleware(...middlewares)));
  sagaMiddleware.run(sagas);
  return store;
}
