import createReducerFactory from "helpers/createReducerFactory";
import createReducerState from "helpers/createReducerState";
import createFormReducer from "helpers/createFormReducer";
import defaultState from "constants/defaultState";

import { loadAll, loadOne, create, uploadImage, update } from "./routines";

const initialState = {
  all: defaultState,
  one: defaultState,
  create: defaultState,
  image: defaultState,
  update: defaultState
};

const factories = {
  ...createReducerState(loadAll, "all"),
  ...createReducerState(loadOne, "one"),
  ...createFormReducer(create, "create"),
  ...createReducerState(uploadImage, "image"),
  ...createFormReducer(update, "update")
};

export default createReducerFactory(initialState, factories);
