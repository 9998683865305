export default (action = {}, name) => {
  return {
    [action.REQUEST](state, _) {
      return {
        ...state,
        [name]: {
          ...state[name],
          loading: true
        }
      };
    },
    [action.SUCCESS](state, { payload }) {
      return {
        ...state,
        [name]: {
          ...state[name],
          meta: payload.meta,
          data: payload.data,
          loading: false,
          error: null
        }
      };
    },
    [action.FAILURE](state, { payload }) {
      return {
        ...state,
        [name]: {
          ...state[name],
          loading: false,
          error: payload
        }
      };
    }
  };
};
