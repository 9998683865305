import createReducerFactory from "helpers/createReducerFactory";
import createReducerState from "helpers/createReducerState";
import createFormReducer from "helpers/createFormReducer";
import defaultState from "constants/defaultState";

import { loadAll, loadOne, create, update, deleteOne, uploadImage, loadAllFlatten } from "./routines";

const initialState = {
  all: defaultState,
  loadAllFlatten: defaultState,
  one: defaultState,
  create: defaultState,
  update: defaultState,
  deleteOne: defaultState,
  image: defaultState
};

const factories = {
  ...createReducerState(loadAll, "all"),
  ...createReducerState(loadAllFlatten, "loadAllFlatten"),
  ...createReducerState(loadOne, "one"),
  ...createFormReducer(create, "create"),
  ...createFormReducer(update, "update"),
  ...createReducerState(deleteOne, "deleteOne"),
  ...createReducerState(uploadImage, "image")
};

export default createReducerFactory(initialState, factories);
