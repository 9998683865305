import { takeLatest, all, call, put } from "redux-saga/effects";

import createRequest from "helpers/createRequest";

import * as routines from "./routines";
import { create, take } from "lodash";

function* LoadAllWatcher({ payload }) {
  try {
    yield put(routines.loadAll.request());

    const response = yield call(createRequest.get, "/users", {
      params: {
        page: payload.page,
        per_page: payload.per_page,
        category_id: payload.category_id,
        organization_id: payload.organization_id,
        ...(payload.filter ? { [payload.filter]: 1 } : {}),
      }
    });
    const { data, ...meta } = response.data.users;

    yield put(routines.loadAll.success({ data, meta }));
  } catch (error) {
    yield put(routines.loadAll.failure());
  } finally {
    yield put(routines.loadAll.fulfill());
  }
}

function* LoadUserOrders({payload}) {
  try {
    yield put(routines.userOrders.request());

    const response = yield call(createRequest.get, `users/${payload.user_id}/orders?_lang=ru`);
    const data = response.data.orders;
    yield put(routines.userOrders.success({ data }));
  } catch(error) {
    yield put(routines.userOrders.failure());
  } finally {
    yield put(routines.userOrders.fulfill());
  }
}

function* LoadUserDocuments({payload}) {
  try {
    yield put(routines.documents.request());

    const response = yield call(createRequest.get, `users/${payload.user_id}/docs`);
    const data = response.data.user_docs;
    yield put(routines.documents.success({ data }));
  } catch(error) {
    yield put(routines.documents.failure());
  } finally {
    yield put(routines.documents.fulfill());
  }
}


function* LoadOneWatcher({ payload }) {
  try {
    yield put(routines.loadOne.request());

    const response = yield call(createRequest.get, `/users/${payload.user_id}`);

    const data = response.data.user;

    yield put(routines.loadOne.success({ data }));
  } catch (error) {
    yield put(routines.loadOne.failure());
  } finally {
    yield put(routines.loadOne.fulfill());
  }
}
function* LoadCompletedCourses({ payload }) {
  try {
    yield put(routines.loadCompletedCourses.request());

    const response = yield call(createRequest.get, `/users/${payload.user_id}/completed-courses`);

    const data = response.data.courses;

    yield put(routines.loadCompletedCourses.success({ data }));
  } catch (error) {
    yield put(routines.loadCompletedCourses.failure());
  } finally {
    yield put(routines.loadCompletedCourses.fulfill());
  }
}
function* LoadSubscriptions({ payload }) {
  try {
    yield put(routines.loadSubscriptions.request());

    const response = yield call(createRequest.get, `/users/${payload.user_id}/courses?_lang=ru`);

    const data = response.data.user_courses;

    yield put(routines.loadSubscriptions.success({ data }));
  } catch (error) {
    yield put(routines.loadSubscriptions.failure());
  } finally {
    yield put(routines.loadSubscriptions.fulfill());
  }
}


function* UserOrderApprove({ payload }) {
  const { order, values, onSuccess = () => {}, onError = () => {} } = payload;
  try {
    yield put(routines.approve.request());
    const response = yield call(createRequest.patch, `/orders/${order}`, { ...values });

    yield put(routines.approve.success(response.data));
    yield call(onSuccess, response.data);
  } catch (error) {
    yield call(onError, error);
  } finally {
    yield put(routines.approve.fulfill());
  }
}

function* UserGetCertificate({ payload }) {
  const { user, values, onSuccess = () => {}, onError = () => {} } = payload;

  try {
    yield put(routines.certificate.request());
    const response = yield call(createRequest.post, `/users/${user}/certificate`, { ...values });

    yield put(routines.certificate.success(response.data));
    yield call(onSuccess, response.data);
  } catch (error) {
    yield call(onError, error);
  } finally {
    yield put(routines.certificate.fulfill());
  }
}

function* LoadSessions({ payload }) {
  try {
    yield put(routines.loadSessions.request());

    const response = yield call(createRequest.get, `/users/${payload.user_id}/sessions`);

    const { data, ...meta } = response.data.user_sessions;

    yield put(routines.loadSessions.success({ data, meta }));
  } catch (error) {
    yield put(routines.loadSessions.failure());
  } finally {
    yield put(routines.loadSessions.fulfill());
  }
}

function* Active({ payload }) {
  const { user, values, onSuccess = () => {}, onError = () => {} } = payload;

  try {
    yield put(routines.active.request());
    const response = yield call(createRequest.patch, `/users/${user}`, { ...values });

    yield put(routines.active.success(response.data.users));
    yield call(onSuccess, response.data.users);
  } catch (error) {
    yield put(routines.active.failure());
    yield call(onError, error);
  } finally {
    yield put(routines.active.fulfill());
  }
}

export default function*() {
  yield all([
    takeLatest(routines.loadAll.TRIGGER, LoadAllWatcher),
    takeLatest(routines.loadOne.TRIGGER, LoadOneWatcher),
    takeLatest(routines.loadCompletedCourses.TRIGGER, LoadCompletedCourses),
    takeLatest(routines.loadSubscriptions.TRIGGER, LoadSubscriptions),
    takeLatest(routines.loadSessions.TRIGGER, LoadSessions),
    takeLatest(routines.active.TRIGGER, Active),
    takeLatest(routines.userOrders.TRIGGER, LoadUserOrders),
    takeLatest(routines.documents.TRIGGER, LoadUserDocuments),
    takeLatest(routines.approve.TRIGGER, UserOrderApprove),
    takeLatest(routines.certificate.TRIGGER, UserGetCertificate)
  ]);
}
