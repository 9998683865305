import cookies from "services/cookies";

import { login, logout } from "./routines";

const { access_token, user, user_permissions, session } = cookies.getAll();

const initialState = {
  isAuthenticated: !!access_token,
  access_token: access_token || null,
  user: user || {},
  user_permissions: user_permissions || [],
  session: session || {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case login.SUCCESS: {
      const { access_token, user, user_permissions, session } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        access_token,
        user,
        user_permissions,
        session
      };
    }
    case logout.SUCCESS: {
      return {
        ...state,
        isAuthenticated: false,
        token: null
      };
    }

    default:
      return state;
  }
};
